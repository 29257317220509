/*
 * displayプロパティに関するutilityのクラスです
 */
$prefix: 'u-dsp';

.#{$prefix}{
  @include pc {
    &-sp{
      display: none !important;
    }
  }
  @include sp {
    &-pc{
      display: none !important;
    }
  }
}
