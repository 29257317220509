/* ==========================================================================
   Utility
   ========================================================================== */
@mixin clearFix{
  &:after{
    content:'.';
    height:0;
    display:block;
    clear:both;
    visibility:hidden;
  }
}

@mixin alphaBtn{
  @include transAnime(.25s);
  &:hover {
    opacity: .7;
  }
}

@mixin placeholderColor($color) {
  &:placeholder-shown {
      color: $color;
  }
  &::-webkit-input-placeholder {
      color:$color;
  }
  &:-moz-placeholder {
      color:$color;
      opacity: 1;
  }
  &::-moz-placeholder {
      color:$color;
      opacity: 1;
  }
  &:-ms-input-placeholder {
      color:$color;
  }
}

@mixin useSvg($color){
  &{
    i{
      display: inline-block;
      width: 100%;
      height: 100%;
    }
    svg{
      display: block;
      width: 100%;
      height: 100%;
      fill: $color;
      @include transAnime(.25s);
    }
  }
}

@mixin transAnime($duration, $target: all, $delay: 0s, $easing: $cubic_bezier){
  transition: $target $duration $easing $delay;
}

@mixin basicIn($direction: 'y'){
  $this: &;
  opacity: 0;
  @if $direction == 'x' {
    transform: translate(20px, 0);
  } @else if $direction == '-x' {
    transform: translate(-20px, 0);
  } @else if $direction == '-y' {
    transform: translate(0, -20px);
  } @else {
    transform: translate(0, 20px);
  }
  transition: opacity .5s, transform .5s;
  transition-timing-function: $cubic_bezier;
  &.is-in{
    opacity: 1;
    transform: none;
  }
}

@mixin centerSet($direction: all){
  position: absolute;
  @if $direction == x {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if $direction == y {
    top: 50%;
    transform: translate(0, -50%);
  } @else {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@mixin boxCenter($height, $line_height: $height){
  height: $height;
  line-height: $line_height;
}

@mixin negativeList($margin, $type: 'hor', $margin_ver: 0){
  &{

    $number: stripUnit($margin);
    $number_ver: stripUnit($margin_ver);

    $unit: unit($margin);
    $unit_ver: unit($margin_ver);

    @if $type == 'hor' {
      margin-right: #{(-$number / 2)}#{$unit};
      margin-left: #{(-$number / 2)}#{$unit};
      >*{
        padding-right: #{($number / 2)}#{$unit};
        padding-left: #{($number / 2)}#{$unit};
      }
    } @else if $type == 'ver' {
      margin-top: #{(-$number / 2)}#{$unit};
      margin-bottom: #{(-$number / 2)}#{$unit};
      >*{
        padding-top: #{($number / 2)}#{$unit};
        padding-bottom: #{($number / 2)}#{$unit};
      }
    } @else if $type == 'all' {
      @if $margin_ver == 0 {
        margin: #{(-$number / 2)}#{$unit};
        >*{
          padding: #{($number / 2)}#{$unit};
        }
      } @else {
        margin: #{-$number_ver / 2}#{$unit_ver} #{-$number / 2}#{$unit};
        >*{
          padding: #{$number_ver / 2}#{$unit_ver} #{$number / 2}#{$unit};
        }
      }
    } @else {
      margin-right: #{(-$number / 2)}#{$unit};
      margin-left: #{(-$number / 2)}#{$unit};
      >*{
        padding-right: #{($number / 2)}#{$unit};
        padding-left: #{($number / 2)}#{$unit};
      }
    }
  }
}

@mixin flexLayout($wrap: wrap, $justify: flex-start, $align: flex-start){
  display: flex;
  flex-wrap: $wrap;
  justify-content: $justify;
  align-items: $align;
}

@mixin expandFull($expand){
  margin: 0 -#{$expand};
}

@mixin imgW100{
  display: block;
  width: 100%;
  max-width: none;
  height: auto;
}

@mixin imgH100{
  display: block;
  width: auto;
  max-width: none;
  height: 100%;
}


/* ==========================================================================
   Font
   ========================================================================== */
@mixin fontSerif($weight: 500){
  font-family: $font_serif;
  font-weight: $weight;
}

/* ==========================================================================
   Line Height
   ========================================================================== */
@mixin lineHeight($pc: 17 / 10, $sp: 23 / 14){
  @include pc {
    line-height: $pc;
  }
  @include sp {
    line-height: $sp;
  }
}



/* ==========================================================================
   Media
   ========================================================================== */
@mixin pc{
  @media #{$media_pc} {
    @content;
  }
}

@mixin pcL{
  @media #{$media_desctop} {
    @content;
  }
}

@mixin sp{
  @media #{$media_sp} {
    @content;
  }
}


/* ==========================================================================
   Layout
   ========================================================================== */

/* Basic Layout
   ----------------------------------------------------------------- */
@mixin basicLayout(){
  &__inner{
    max-width: makeRem($inner_w_pc + $pad_hor_pc * 2);
    margin: 0 auto;
  }
  @include pc {
    &__inner{
      padding-right: makeRem($pad_hor_pc);
      padding-left: makeRem($pad_hor_pc);
    }
  }
  @include sp {
    &__inner{
      padding-right: makeRem($pad_hor_sp);
      padding-left: makeRem($pad_hor_sp);
    }
  }
}

/* Global Layout
   ----------------------------------------------------------------- */
@mixin wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  @include pc {
    max-width: 1360px;
    margin: 0 auto;
  }
  @include sp {
    min-width: 320px;
  }
}

//ナビゲーション用アニメーション
@keyframes bounce_3375 {
  0% { transform:translateY(0) }
  12.5% { transform:translateY(0) }
  25% { transform:translateY(0) }
  50% { transform:translateY(-15px) }
  62.5% { transform:translateY(0) }
  75% { transform:translateY(-15px) }
  100% { transform:translateY(0) }
}

@mixin header{
  @include basicLayout;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
  z-index: $header_index;
  &__inner{
    height: 100%;
  }
  &__logo{
    a{
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  @include pc {
    height: makeRem($header_h_pc);
    background-color: $col_bg;
    transition: 0.6s;
    &__inner{
      @include flexLayout(wrap, center, center);
      padding-right: makeRem($pad_hor_pc);
      padding-left: makeRem($pad_hor_pc);
    }
    &.is-animation{
      height: makeRem(80);
    }
    &__logo{
      width: makeRem(60);
      @include centerSet;
      transition: 0.6s;
      a{
        &:hover{
          opacity: 0.7;
        }
      }
    }
    &.is-animation &__logo{
      width: makeRem(42);
      height: makeRem(70);
    }
    &__nav{
      width: 100%;
    }
    &__navList{
      @include flexLayout(wrap, space-between, center);
      &Left{
        width: 50%;
        ul{
          @include flexLayout(wrap, center, center);
          li{
            width: 30%;
            &:first-child{
              width: 40%;
            }
            img{
              margin: 0 auto;
            }
          }
        }
      }
      &Right{
        width: 50%;
        ul{
          @include flexLayout(wrap, center, center);
          li{
            width: 40%;
            &:last-child{
              width: 30%;
            }
            img{
              margin: 0 auto;
            }
          }
        }
      }
      &Item{
        &:hover{
          animation: bounce_3375 1.6s ease infinite;
          transform-origin: 50% 50%;
        }
      }
    }
    
  }
  @include pcL {
  }
  @include sp {
    height: makeRem($header_h_sp);
    &__inner{
      padding-right: makeRem(15);
      padding-left: makeRem(15);
      position: relative;
    }
    &__logo{
      width: makeVw(80,750);
      height: makeVw(134,750);
      margin-top: makeVw(38,750);
      margin-left: makeVw(42,750);
      position: relative;
      z-index: 9999;
    }
    &__nav{
      display: none;
    }
    
  }
}

@mixin footer{
  @include basicLayout;
  width: 100%;
  &__copyright{
    text-align: center;
    font-size: makeRem(11);
    font-weight: 300;
    @include lineHeight(1, 26/14);
    letter-spacing: $lts125;
  }
  @include pc {
    &__bottom{
      padding-top: makeRem(60);
      padding-bottom: makeRem(60);
    }
    &__btn{
      width: makeRem(73);
      margin: 0 auto makeRem(60);
    }
  }
  @include pcL {
  }
  @include sp {
    &__bottom{
      padding-top: makeRem(45);
      padding-bottom: makeRem(45);
    }
    &__btn{
      width: makeVw(73);
      margin: 0 auto makeVw(60);
    }
  }
}

@mixin globalNav{
  @include pc {
  }
  @include pcL {
  }
  @include sp {
  }
}

@mixin contents{
  display: block; // for IE11
  width: 100%;
  flex-grow: 1;
  @include pc {
    padding-top: makeRem(140);
  }
  @include sp {
    padding-top: makeRem(75);
  }
}

@mixin section{
  @include basicLayout;
}

/* Column Layout
   ----------------------------------------------------------------- */
