/* ==========================================================================
   Accordion
   ========================================================================== */
.c-accordion{

  @mixin accordion(){
    overflow: hidden;
    &__trigger{
      display: none;
    }
    &__content{
      visibility: hidden;
      opacity: 0;
      height: 0;
      transform: translateY(-100%);
      transform-origin: top center;
      @include transAnime(.5s);
      @include transAnime(.5s);
      transition: opacity .5s $cubic_bezier, visibility .5s $cubic_bezier, padding .5s $cubic_bezier, transform .5s $cubic_bezier;
    }
    &__trigger:checked + &{
      &__content{
        visibility: visible;
        opacity: 1;
        height: auto;
        transform: none;
      }
    }
    @include pc {
    }
    @include pcL {
    }
    @include sp {
    }
  }

  /* 基本レイアウト */
  @include accordion;

}
