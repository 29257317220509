.p-ig{
  @include basicLayout;
  opacity: 0;
  &__inner{
    position: relative;
  }
  @include pc{
    &__title{
      img{
        width: makeRem(198);
        margin: 0 auto makeRem(60);
      }
    }
    &__feed{
      width: 495px;
      margin: 0 auto makeRem(140);
    }
    &__gallery{
      display: inline-block;
      margin: makeRem(8) makeRem(10);
      img{
        width: makeRem(145);
      }
    }
    &__text{
      width: makeRem(171);
      position: absolute;
      left: makeRem(200);
      bottom: -(makeRem(70));
    }

  }

  @include sp{
    &__title{
      img{
        width: makeVw(148);
        margin: 0 auto makeVw(25);
      }
    }
    &__feed{
      width: 100%;
      margin: 0 auto makeVw(115);
      @include flexLayout(wrap, space-around, center);
    }
    &__gallery{
      width: 30%;
      margin-bottom: makeVw(10);
    }
    &__text{
      width: makeVw(143);
      position: absolute;
      right: makeVw(10);
      bottom: -(makeVw(60));
    }
  }
  
}