@charset "UTF-8";
/* ==========================================================================
   Setting
   ========================================================================== */
/* Variables
   ----------------------------------------------------------------- */
/* ==========================================================================
   Size
   ========================================================================== */
/* ==========================================================================
   Media
   ========================================================================== */
/* ==========================================================================
   Ratio
   ========================================================================== */
/* ==========================================================================
   Z-index
   ========================================================================== */
/* ==========================================================================
   Easing
   ========================================================================== */
/* ==========================================================================
   Letter Spacing
   ========================================================================== */
/* Fonts
   ----------------------------------------------------------------- */
/* ==========================================================================
   Font Face
   ========================================================================== */
/* Font Face mixin
   ----------------------------------------------------------------- */
/* Include Noto Sans
   ----------------------------------------------------------------- */
@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-display: block;
  font-weight: 300;
  src: url("../fonts/NotoSansCJKjp-DemiLight.min.woff2") format("woff2"), url("../fonts/NotoSansCJKjp-DemiLight.min.woff") format("woff"); }

@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-display: block;
  font-weight: 400;
  src: url("../fonts/NotoSansCJKjp-Regular.min.woff2") format("woff2"), url("../fonts/NotoSansCJKjp-Regular.min.woff") format("woff"); }

@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-display: block;
  font-weight: 500;
  src: url("../fonts/NotoSansCJKjp-Medium.min.woff2") format("woff2"), url("../fonts/NotoSansCJKjp-Medium.min.woff") format("woff"); }

/* Include Noto Serif
   ----------------------------------------------------------------- */
@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-display: block;
  font-weight: 500;
  src: url("../fonts/NotoSerifCJKjp-Medium.min.woff2") format("woff2"), url("../fonts/NotoSerifCJKjp-Medium.min.woff") format("woff"); }

@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-display: block;
  font-weight: 700;
  src: url("../fonts/NotoSerifCJKjp-Bold.min.woff2") format("woff2"), url("../fonts/NotoSerifCJKjp-Bold.min.woff") format("woff"); }

@font-face {
  font-family: 'eriji';
  src: url("../../fonts/eriji.woff") format("woff"), url("../../fonts/eriji.otf") format("opentype"); }

/* ==========================================================================
   Family
   ========================================================================== */
/* Main
   ----------------------------------------------------------------- */
/* Serif
   ----------------------------------------------------------------- */
/* Colors
   ----------------------------------------------------------------- */
/* ==========================================================================
   Basic
   ========================================================================== */
/* ==========================================================================
   Parts
   ========================================================================== */
/* Background
    ----------------------------------------------------------------- */
/* Animations
   ----------------------------------------------------------------- */
/* ==========================================================================
   Blink
   ========================================================================== */
@keyframes blink {
  75% {
    opacity: 0; } }

/* ==========================================================================
   Scroller
   ========================================================================== */
@keyframes scrollLeft {
  0% {
    transform: translate3d(-100%, 0, 0);
    opacity: 0; }
  15% {
    transform: translate3d(-98%, 0, 0); }
  85% {
    transform: translate3d(-15%, 0, 0);
    opacity: 0.5; }
  100% {
    transform: translate3d(0%, 0, 0);
    opacity: 1; } }

@keyframes scrollRight {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0; }
  15% {
    transform: translate3d(98%, 0, 0); }
  85% {
    transform: translate3d(15%, 0, 0);
    opacity: 0.5; }
  100% {
    transform: translate3d(0%, 0, 0);
    opacity: 1; } }

@keyframes scrollBottom {
  0% {
    transform: translate3d(0, 20%, 0);
    opacity: 0; }
  15% {
    transform: translate3d(0, 18%, 0); }
  85% {
    transform: translate3d(0, 5%, 0);
    opacity: 0.5; }
  100% {
    transform: translate3d(0, 0%, 0);
    opacity: 1; } }

/* ==========================================================================
   Scroll In
   ========================================================================== */
@keyframes scrollIn {}.c-animatedLeft {
  -webkit-animation-name: scrollLeft;
  animation-name: scrollLeft;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.c-animatedRight {
  -webkit-animation-name: scrollRight;
  animation-name: scrollRight;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.c-animatedBottom {
  -webkit-animation-name: scrollBottom;
  animation-name: scrollBottom;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.c-animatedFadeIn {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

/* ==========================================================================
   Tool
   ========================================================================== */
/* Function
   ----------------------------------------------------------------- */
/* ==========================================================================
   Size
   ========================================================================== */
/* ==========================================================================
   Unit
   ========================================================================== */
/*
 * 数値から単位を分離して返す
 * @params $number : 単位付きのcssの値です
 */
/*
 * 数値から単位だけを返す
 * @params $number : 単位付きのcssの値です
 */
/* ==========================================================================
   Number
   ========================================================================== */
/*
 * 数値を四捨五入して返す
 * @params $number : 数値
 * @params $digits : 折り返す桁数
 */
/*
 * 数値を切り上げて返す
 * @params $number : 数値
 * @params $digits : 折り返す桁数
 */
/*
 * 数値を切り捨てて返す
 * @params $number : 数値
 * @params $digits : 折り返す桁数
 */
/* ==========================================================================
   Array
   ========================================================================== */
/* Mixin
   ----------------------------------------------------------------- */
/* ==========================================================================
   Utility
   ========================================================================== */
/* ==========================================================================
   Font
   ========================================================================== */
/* ==========================================================================
   Line Height
   ========================================================================== */
/* ==========================================================================
   Media
   ========================================================================== */
/* ==========================================================================
   Layout
   ========================================================================== */
/* Basic Layout
   ----------------------------------------------------------------- */
/* Global Layout
   ----------------------------------------------------------------- */
@keyframes bounce_3375 {
  0% {
    transform: translateY(0); }
  12.5% {
    transform: translateY(0); }
  25% {
    transform: translateY(0); }
  50% {
    transform: translateY(-15px); }
  62.5% {
    transform: translateY(0); }
  75% {
    transform: translateY(-15px); }
  100% {
    transform: translateY(0); } }

/* Column Layout
   ----------------------------------------------------------------- */
/* ==========================================================================
   Foundation
   ========================================================================== */
/* Reset
   ----------------------------------------------------------------- */
/*! sanitize.css v4.1.0 | CC0 License | github.com/jonathantneal/sanitize.css */
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

template,
[hidden] {
  display: none; }

*,
::before,
::after {
  background-repeat: no-repeat;
  box-sizing: inherit; }

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit; }

html {
  box-sizing: border-box;
  cursor: default;
  font-family: sans-serif;
  line-height: 1.5;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

h1 {
  font-size: 2em;
  margin: .67em 0; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

hr {
  height: 0;
  overflow: visible; }

nav ol,
nav ul {
  list-style: none; }

abbr[title] {
  border-bottom: 1px dotted;
  text-decoration: none; }

b,
strong {
  font-weight: inherit; }

b,
strong {
  font-weight: bolder; }

dfn {
  font-style: italic; }

mark {
  background-color: #ff0;
  color: #000; }

progress {
  vertical-align: baseline; }

small {
  font-size: 83.3333%; }

sub,
sup {
  font-size: 83.3333%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

::-moz-selection {
  background-color: #b3d4fc;
  color: #000;
  text-shadow: none; }

::selection {
  background-color: #b3d4fc;
  color: #000;
  text-shadow: none; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

img {
  border-style: none; }

svg {
  fill: currentColor; }
  svg:not(:root) {
    overflow: hidden; }

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    outline-width: 0; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

button,
input,
select,
textarea {
  background-color: transparent;
  border-style: none;
  color: inherit;
  font-size: 1em;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; }

::-moz-focus-inner {
  border-style: none;
  padding: 0; }

:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: .35em .625em .75em; }

legend {
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

textarea {
  overflow: auto;
  resize: vertical; }

[type='checkbox'],
[type='radio'] {
  padding: 0; }

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto; }

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

[aria-busy='true'] {
  cursor: progress; }

[aria-controls] {
  cursor: pointer; }

[aria-disabled] {
  cursor: default; }

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation; }

[hidden][aria-hidden='false'] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute; }
  [hidden][aria-hidden='false']:focus {
    clip: auto; }

/* ==========================================================================
   My Reset
   ========================================================================== */
* {
  margin: 0;
  padding: 0; }

h1 {
  font-size: 1em;
  font-weight: 400;
  margin: 0; }

h2 {
  font-size: 1em;
  font-weight: 400;
  margin: 0; }

h3 {
  font-size: 1em;
  font-weight: 400;
  margin: 0; }

h4 {
  font-size: 1em;
  font-weight: 400;
  margin: 0; }

h5 {
  font-size: 1em;
  font-weight: 400;
  margin: 0; }

h6 {
  font-size: 1em;
  font-weight: 400;
  margin: 0; }

ul li, ol li {
  list-style: none; }

a {
  color: #333;
  text-decoration: none;
  outline: none; }

img {
  display: block;
  max-width: 100%;
  height: auto; }

input, button, textarea, select {
  appearance: none;
  outline: none; }

button {
  cursor: pointer; }

address {
  font-style: normal; }

/* Base
   ----------------------------------------------------------------- */
@-ms-viewport {
  width: auto;
  initial-scale: 1; }

@viewport {
  width: device-width;
  initial-scale: 1; }

html {
  width: 100%;
  height: 100%; }
  @media only screen and (min-width: 769px) {
    html {
      font-size: 62.5%; } }
  @media only screen and (max-width: 768px) {
    html {
      font-size: 2.66667vw; } }

body {
  width: inherit;
  height: inherit;
  color: #666;
  font-family: "M+ Type-2 (general-j) Regular", sans-serif;
  font-weight: 400;
  letter-spacing: 0.04em;
  background: #ebe9dc;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: 'palt';
  font-feature-settings: 'palt'; }
  @media only screen and (min-width: 769px) {
    body {
      line-height: 1.7; } }
  @media only screen and (max-width: 768px) {
    body {
      line-height: 1.64286; } }
  @media only screen and (min-width: 769px) {
    body {
      font-size: 1.3rem; } }
  @media only screen and (max-width: 768px) {
    body {
      font-size: 1.4rem; } }

/* ==========================================================================
   Common Parts
   ========================================================================== */
/* ==========================================================================
   Layout
   ========================================================================== */
.l-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100%;
  overflow: hidden; }
  @media only screen and (min-width: 769px) {
    .l-wrapper {
      max-width: 1360px;
      margin: 0 auto; } }
  @media only screen and (max-width: 768px) {
    .l-wrapper {
      min-width: 320px; } }

/* ==========================================================================
   Header
   ========================================================================== */
.l-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
  z-index: 10; }
  .l-header__inner {
    max-width: 114rem;
    margin: 0 auto; }
  @media only screen and (min-width: 769px) {
    .l-header__inner {
      padding-right: 4rem;
      padding-left: 4rem; } }
  @media only screen and (max-width: 768px) {
    .l-header__inner {
      padding-right: 1rem;
      padding-left: 1rem; } }
  .l-header__inner {
    height: 100%; }
  .l-header__logo a {
    display: block;
    width: 100%;
    height: 100%; }
  @media only screen and (min-width: 769px) {
    .l-header {
      height: 18rem;
      background-color: #ebe9dc;
      transition: 0.6s; }
      .l-header__inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding-right: 4rem;
        padding-left: 4rem; }
      .l-header.is-animation {
        height: 8rem; }
      .l-header__logo {
        width: 6rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: 0.6s; }
        .l-header__logo a:hover {
          opacity: 0.7; }
      .l-header.is-animation .l-header__logo {
        width: 4.2rem;
        height: 7rem; }
      .l-header__nav {
        width: 100%; }
      .l-header__navList {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center; }
        .l-header__navListLeft {
          width: 50%; }
          .l-header__navListLeft ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center; }
            .l-header__navListLeft ul li {
              width: 30%; }
              .l-header__navListLeft ul li:first-child {
                width: 40%; }
              .l-header__navListLeft ul li img {
                margin: 0 auto; }
        .l-header__navListRight {
          width: 50%; }
          .l-header__navListRight ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center; }
            .l-header__navListRight ul li {
              width: 40%; }
              .l-header__navListRight ul li:last-child {
                width: 30%; }
              .l-header__navListRight ul li img {
                margin: 0 auto; }
        .l-header__navListItem:hover {
          animation: bounce_3375 1.6s ease infinite;
          transform-origin: 50% 50%; } }
  @media only screen and (max-width: 768px) {
    .l-header {
      height: 11rem; }
      .l-header__inner {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        position: relative; }
      .l-header__logo {
        width: 10.66667vw;
        height: 17.86667vw;
        margin-top: 5.06667vw;
        margin-left: 5.6vw;
        position: relative;
        z-index: 9999; }
      .l-header__nav {
        display: none; } }

/* ==========================================================================
   Footer
   ========================================================================== */
.l-footer {
  width: 100%; }
  .l-footer__inner {
    max-width: 114rem;
    margin: 0 auto; }
  @media only screen and (min-width: 769px) {
    .l-footer__inner {
      padding-right: 4rem;
      padding-left: 4rem; } }
  @media only screen and (max-width: 768px) {
    .l-footer__inner {
      padding-right: 1rem;
      padding-left: 1rem; } }
  .l-footer__copyright {
    text-align: center;
    font-size: 1.1rem;
    font-weight: 300;
    letter-spacing: 0.1em; }
    @media only screen and (min-width: 769px) {
      .l-footer__copyright {
        line-height: 1; } }
    @media only screen and (max-width: 768px) {
      .l-footer__copyright {
        line-height: 1.85714; } }
  @media only screen and (min-width: 769px) {
    .l-footer__bottom {
      padding-top: 6rem;
      padding-bottom: 6rem; }
    .l-footer__btn {
      width: 7.3rem;
      margin: 0 auto 6rem; } }
  @media only screen and (max-width: 768px) {
    .l-footer__bottom {
      padding-top: 4.5rem;
      padding-bottom: 4.5rem; }
    .l-footer__btn {
      width: 19.46667vw;
      margin: 0 auto 16vw; } }

/* ==========================================================================
   Contents
   ========================================================================== */
.l-contents {
  display: block;
  width: 100%;
  flex-grow: 1; }
  @media only screen and (min-width: 769px) {
    .l-contents {
      padding-top: 14rem; } }
  @media only screen and (max-width: 768px) {
    .l-contents {
      padding-top: 7.5rem; } }

/* ==========================================================================
   Section
   ========================================================================== */
.l-section {
  /* SP no padding */ }
  .l-section__inner {
    max-width: 114rem;
    margin: 0 auto; }
  @media only screen and (min-width: 769px) {
    .l-section__inner {
      padding-right: 4rem;
      padding-left: 4rem; } }
  @media only screen and (max-width: 768px) {
    .l-section__inner {
      padding-right: 1rem;
      padding-left: 1rem; } }
  .l-section--spFull__inner {
    max-width: 114rem;
    margin: 0 auto; }
  @media only screen and (min-width: 769px) {
    .l-section--spFull__inner {
      padding-right: 4rem;
      padding-left: 4rem; } }
  @media only screen and (max-width: 768px) {
    .l-section--spFull__inner {
      padding-right: 1rem;
      padding-left: 1rem; } }
  @media only screen and (max-width: 768px) {
    .l-section--spFull {
      max-width: none; }
      .l-section--spFull__inner {
        padding-right: 0;
        padding-left: 0; } }

/* ==========================================================================
   Object
   ========================================================================== */
/* Component
   ----------------------------------------------------------------- */
/* for svg */
/* ==========================================================================
   Svg Definition
   ========================================================================== */
.c-svgDef {
  /* 基本レイアウト */ }
  .c-svgDef {
    display: none; }

/* for icon */
/* for grid */
/* ==========================================================================
   Grid
   ========================================================================== */
.c-grid {
  /* 親の設定 */
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  /* 子要素の設定 */ }
  @media only screen and (min-width: 769px) {
    .c-grid__cell1 {
      width: 8.33333%; }
      .c-grid__cell1--sp1 {
        width: 8.33333%; }
      .c-grid__cell1--sp2 {
        width: 8.33333%; }
      .c-grid__cell1--sp3 {
        width: 8.33333%; }
      .c-grid__cell1--sp4 {
        width: 8.33333%; }
      .c-grid__cell1--sp5 {
        width: 8.33333%; }
      .c-grid__cell1--sp6 {
        width: 8.33333%; }
      .c-grid__cell1--sp7 {
        width: 8.33333%; }
      .c-grid__cell1--sp8 {
        width: 8.33333%; }
      .c-grid__cell1--sp9 {
        width: 8.33333%; }
      .c-grid__cell1--sp10 {
        width: 8.33333%; }
      .c-grid__cell1--sp11 {
        width: 8.33333%; }
      .c-grid__cell1--sp12 {
        width: 8.33333%; } }
  @media only screen and (max-width: 768px) {
    .c-grid__cell1--sp1 {
      width: 8.33333%; }
    .c-grid__cell1--sp2 {
      width: 16.66667%; }
    .c-grid__cell1--sp3 {
      width: 25%; }
    .c-grid__cell1--sp4 {
      width: 33.33333%; }
    .c-grid__cell1--sp5 {
      width: 41.66667%; }
    .c-grid__cell1--sp6 {
      width: 50%; }
    .c-grid__cell1--sp7 {
      width: 58.33333%; }
    .c-grid__cell1--sp8 {
      width: 66.66667%; }
    .c-grid__cell1--sp9 {
      width: 75%; }
    .c-grid__cell1--sp10 {
      width: 83.33333%; }
    .c-grid__cell1--sp11 {
      width: 91.66667%; }
    .c-grid__cell1--sp12 {
      width: 100%; } }
  @media only screen and (min-width: 769px) {
    .c-grid__cell2 {
      width: 16.66667%; }
      .c-grid__cell2--sp1 {
        width: 16.66667%; }
      .c-grid__cell2--sp2 {
        width: 16.66667%; }
      .c-grid__cell2--sp3 {
        width: 16.66667%; }
      .c-grid__cell2--sp4 {
        width: 16.66667%; }
      .c-grid__cell2--sp5 {
        width: 16.66667%; }
      .c-grid__cell2--sp6 {
        width: 16.66667%; }
      .c-grid__cell2--sp7 {
        width: 16.66667%; }
      .c-grid__cell2--sp8 {
        width: 16.66667%; }
      .c-grid__cell2--sp9 {
        width: 16.66667%; }
      .c-grid__cell2--sp10 {
        width: 16.66667%; }
      .c-grid__cell2--sp11 {
        width: 16.66667%; }
      .c-grid__cell2--sp12 {
        width: 16.66667%; } }
  @media only screen and (max-width: 768px) {
    .c-grid__cell2--sp1 {
      width: 8.33333%; }
    .c-grid__cell2--sp2 {
      width: 16.66667%; }
    .c-grid__cell2--sp3 {
      width: 25%; }
    .c-grid__cell2--sp4 {
      width: 33.33333%; }
    .c-grid__cell2--sp5 {
      width: 41.66667%; }
    .c-grid__cell2--sp6 {
      width: 50%; }
    .c-grid__cell2--sp7 {
      width: 58.33333%; }
    .c-grid__cell2--sp8 {
      width: 66.66667%; }
    .c-grid__cell2--sp9 {
      width: 75%; }
    .c-grid__cell2--sp10 {
      width: 83.33333%; }
    .c-grid__cell2--sp11 {
      width: 91.66667%; }
    .c-grid__cell2--sp12 {
      width: 100%; } }
  @media only screen and (min-width: 769px) {
    .c-grid__cell3 {
      width: 25%; }
      .c-grid__cell3--sp1 {
        width: 25%; }
      .c-grid__cell3--sp2 {
        width: 25%; }
      .c-grid__cell3--sp3 {
        width: 25%; }
      .c-grid__cell3--sp4 {
        width: 25%; }
      .c-grid__cell3--sp5 {
        width: 25%; }
      .c-grid__cell3--sp6 {
        width: 25%; }
      .c-grid__cell3--sp7 {
        width: 25%; }
      .c-grid__cell3--sp8 {
        width: 25%; }
      .c-grid__cell3--sp9 {
        width: 25%; }
      .c-grid__cell3--sp10 {
        width: 25%; }
      .c-grid__cell3--sp11 {
        width: 25%; }
      .c-grid__cell3--sp12 {
        width: 25%; } }
  @media only screen and (max-width: 768px) {
    .c-grid__cell3--sp1 {
      width: 8.33333%; }
    .c-grid__cell3--sp2 {
      width: 16.66667%; }
    .c-grid__cell3--sp3 {
      width: 25%; }
    .c-grid__cell3--sp4 {
      width: 33.33333%; }
    .c-grid__cell3--sp5 {
      width: 41.66667%; }
    .c-grid__cell3--sp6 {
      width: 50%; }
    .c-grid__cell3--sp7 {
      width: 58.33333%; }
    .c-grid__cell3--sp8 {
      width: 66.66667%; }
    .c-grid__cell3--sp9 {
      width: 75%; }
    .c-grid__cell3--sp10 {
      width: 83.33333%; }
    .c-grid__cell3--sp11 {
      width: 91.66667%; }
    .c-grid__cell3--sp12 {
      width: 100%; } }
  @media only screen and (min-width: 769px) {
    .c-grid__cell4 {
      width: 33.33333%; }
      .c-grid__cell4--sp1 {
        width: 33.33333%; }
      .c-grid__cell4--sp2 {
        width: 33.33333%; }
      .c-grid__cell4--sp3 {
        width: 33.33333%; }
      .c-grid__cell4--sp4 {
        width: 33.33333%; }
      .c-grid__cell4--sp5 {
        width: 33.33333%; }
      .c-grid__cell4--sp6 {
        width: 33.33333%; }
      .c-grid__cell4--sp7 {
        width: 33.33333%; }
      .c-grid__cell4--sp8 {
        width: 33.33333%; }
      .c-grid__cell4--sp9 {
        width: 33.33333%; }
      .c-grid__cell4--sp10 {
        width: 33.33333%; }
      .c-grid__cell4--sp11 {
        width: 33.33333%; }
      .c-grid__cell4--sp12 {
        width: 33.33333%; } }
  @media only screen and (max-width: 768px) {
    .c-grid__cell4--sp1 {
      width: 8.33333%; }
    .c-grid__cell4--sp2 {
      width: 16.66667%; }
    .c-grid__cell4--sp3 {
      width: 25%; }
    .c-grid__cell4--sp4 {
      width: 33.33333%; }
    .c-grid__cell4--sp5 {
      width: 41.66667%; }
    .c-grid__cell4--sp6 {
      width: 50%; }
    .c-grid__cell4--sp7 {
      width: 58.33333%; }
    .c-grid__cell4--sp8 {
      width: 66.66667%; }
    .c-grid__cell4--sp9 {
      width: 75%; }
    .c-grid__cell4--sp10 {
      width: 83.33333%; }
    .c-grid__cell4--sp11 {
      width: 91.66667%; }
    .c-grid__cell4--sp12 {
      width: 100%; } }
  @media only screen and (min-width: 769px) {
    .c-grid__cell5 {
      width: 41.66667%; }
      .c-grid__cell5--sp1 {
        width: 41.66667%; }
      .c-grid__cell5--sp2 {
        width: 41.66667%; }
      .c-grid__cell5--sp3 {
        width: 41.66667%; }
      .c-grid__cell5--sp4 {
        width: 41.66667%; }
      .c-grid__cell5--sp5 {
        width: 41.66667%; }
      .c-grid__cell5--sp6 {
        width: 41.66667%; }
      .c-grid__cell5--sp7 {
        width: 41.66667%; }
      .c-grid__cell5--sp8 {
        width: 41.66667%; }
      .c-grid__cell5--sp9 {
        width: 41.66667%; }
      .c-grid__cell5--sp10 {
        width: 41.66667%; }
      .c-grid__cell5--sp11 {
        width: 41.66667%; }
      .c-grid__cell5--sp12 {
        width: 41.66667%; } }
  @media only screen and (max-width: 768px) {
    .c-grid__cell5--sp1 {
      width: 8.33333%; }
    .c-grid__cell5--sp2 {
      width: 16.66667%; }
    .c-grid__cell5--sp3 {
      width: 25%; }
    .c-grid__cell5--sp4 {
      width: 33.33333%; }
    .c-grid__cell5--sp5 {
      width: 41.66667%; }
    .c-grid__cell5--sp6 {
      width: 50%; }
    .c-grid__cell5--sp7 {
      width: 58.33333%; }
    .c-grid__cell5--sp8 {
      width: 66.66667%; }
    .c-grid__cell5--sp9 {
      width: 75%; }
    .c-grid__cell5--sp10 {
      width: 83.33333%; }
    .c-grid__cell5--sp11 {
      width: 91.66667%; }
    .c-grid__cell5--sp12 {
      width: 100%; } }
  @media only screen and (min-width: 769px) {
    .c-grid__cell6 {
      width: 50%; }
      .c-grid__cell6--sp1 {
        width: 50%; }
      .c-grid__cell6--sp2 {
        width: 50%; }
      .c-grid__cell6--sp3 {
        width: 50%; }
      .c-grid__cell6--sp4 {
        width: 50%; }
      .c-grid__cell6--sp5 {
        width: 50%; }
      .c-grid__cell6--sp6 {
        width: 50%; }
      .c-grid__cell6--sp7 {
        width: 50%; }
      .c-grid__cell6--sp8 {
        width: 50%; }
      .c-grid__cell6--sp9 {
        width: 50%; }
      .c-grid__cell6--sp10 {
        width: 50%; }
      .c-grid__cell6--sp11 {
        width: 50%; }
      .c-grid__cell6--sp12 {
        width: 50%; } }
  @media only screen and (max-width: 768px) {
    .c-grid__cell6--sp1 {
      width: 8.33333%; }
    .c-grid__cell6--sp2 {
      width: 16.66667%; }
    .c-grid__cell6--sp3 {
      width: 25%; }
    .c-grid__cell6--sp4 {
      width: 33.33333%; }
    .c-grid__cell6--sp5 {
      width: 41.66667%; }
    .c-grid__cell6--sp6 {
      width: 50%; }
    .c-grid__cell6--sp7 {
      width: 58.33333%; }
    .c-grid__cell6--sp8 {
      width: 66.66667%; }
    .c-grid__cell6--sp9 {
      width: 75%; }
    .c-grid__cell6--sp10 {
      width: 83.33333%; }
    .c-grid__cell6--sp11 {
      width: 91.66667%; }
    .c-grid__cell6--sp12 {
      width: 100%; } }
  @media only screen and (min-width: 769px) {
    .c-grid__cell7 {
      width: 58.33333%; }
      .c-grid__cell7--sp1 {
        width: 58.33333%; }
      .c-grid__cell7--sp2 {
        width: 58.33333%; }
      .c-grid__cell7--sp3 {
        width: 58.33333%; }
      .c-grid__cell7--sp4 {
        width: 58.33333%; }
      .c-grid__cell7--sp5 {
        width: 58.33333%; }
      .c-grid__cell7--sp6 {
        width: 58.33333%; }
      .c-grid__cell7--sp7 {
        width: 58.33333%; }
      .c-grid__cell7--sp8 {
        width: 58.33333%; }
      .c-grid__cell7--sp9 {
        width: 58.33333%; }
      .c-grid__cell7--sp10 {
        width: 58.33333%; }
      .c-grid__cell7--sp11 {
        width: 58.33333%; }
      .c-grid__cell7--sp12 {
        width: 58.33333%; } }
  @media only screen and (max-width: 768px) {
    .c-grid__cell7--sp1 {
      width: 8.33333%; }
    .c-grid__cell7--sp2 {
      width: 16.66667%; }
    .c-grid__cell7--sp3 {
      width: 25%; }
    .c-grid__cell7--sp4 {
      width: 33.33333%; }
    .c-grid__cell7--sp5 {
      width: 41.66667%; }
    .c-grid__cell7--sp6 {
      width: 50%; }
    .c-grid__cell7--sp7 {
      width: 58.33333%; }
    .c-grid__cell7--sp8 {
      width: 66.66667%; }
    .c-grid__cell7--sp9 {
      width: 75%; }
    .c-grid__cell7--sp10 {
      width: 83.33333%; }
    .c-grid__cell7--sp11 {
      width: 91.66667%; }
    .c-grid__cell7--sp12 {
      width: 100%; } }
  @media only screen and (min-width: 769px) {
    .c-grid__cell8 {
      width: 66.66667%; }
      .c-grid__cell8--sp1 {
        width: 66.66667%; }
      .c-grid__cell8--sp2 {
        width: 66.66667%; }
      .c-grid__cell8--sp3 {
        width: 66.66667%; }
      .c-grid__cell8--sp4 {
        width: 66.66667%; }
      .c-grid__cell8--sp5 {
        width: 66.66667%; }
      .c-grid__cell8--sp6 {
        width: 66.66667%; }
      .c-grid__cell8--sp7 {
        width: 66.66667%; }
      .c-grid__cell8--sp8 {
        width: 66.66667%; }
      .c-grid__cell8--sp9 {
        width: 66.66667%; }
      .c-grid__cell8--sp10 {
        width: 66.66667%; }
      .c-grid__cell8--sp11 {
        width: 66.66667%; }
      .c-grid__cell8--sp12 {
        width: 66.66667%; } }
  @media only screen and (max-width: 768px) {
    .c-grid__cell8--sp1 {
      width: 8.33333%; }
    .c-grid__cell8--sp2 {
      width: 16.66667%; }
    .c-grid__cell8--sp3 {
      width: 25%; }
    .c-grid__cell8--sp4 {
      width: 33.33333%; }
    .c-grid__cell8--sp5 {
      width: 41.66667%; }
    .c-grid__cell8--sp6 {
      width: 50%; }
    .c-grid__cell8--sp7 {
      width: 58.33333%; }
    .c-grid__cell8--sp8 {
      width: 66.66667%; }
    .c-grid__cell8--sp9 {
      width: 75%; }
    .c-grid__cell8--sp10 {
      width: 83.33333%; }
    .c-grid__cell8--sp11 {
      width: 91.66667%; }
    .c-grid__cell8--sp12 {
      width: 100%; } }
  @media only screen and (min-width: 769px) {
    .c-grid__cell9 {
      width: 75%; }
      .c-grid__cell9--sp1 {
        width: 75%; }
      .c-grid__cell9--sp2 {
        width: 75%; }
      .c-grid__cell9--sp3 {
        width: 75%; }
      .c-grid__cell9--sp4 {
        width: 75%; }
      .c-grid__cell9--sp5 {
        width: 75%; }
      .c-grid__cell9--sp6 {
        width: 75%; }
      .c-grid__cell9--sp7 {
        width: 75%; }
      .c-grid__cell9--sp8 {
        width: 75%; }
      .c-grid__cell9--sp9 {
        width: 75%; }
      .c-grid__cell9--sp10 {
        width: 75%; }
      .c-grid__cell9--sp11 {
        width: 75%; }
      .c-grid__cell9--sp12 {
        width: 75%; } }
  @media only screen and (max-width: 768px) {
    .c-grid__cell9--sp1 {
      width: 8.33333%; }
    .c-grid__cell9--sp2 {
      width: 16.66667%; }
    .c-grid__cell9--sp3 {
      width: 25%; }
    .c-grid__cell9--sp4 {
      width: 33.33333%; }
    .c-grid__cell9--sp5 {
      width: 41.66667%; }
    .c-grid__cell9--sp6 {
      width: 50%; }
    .c-grid__cell9--sp7 {
      width: 58.33333%; }
    .c-grid__cell9--sp8 {
      width: 66.66667%; }
    .c-grid__cell9--sp9 {
      width: 75%; }
    .c-grid__cell9--sp10 {
      width: 83.33333%; }
    .c-grid__cell9--sp11 {
      width: 91.66667%; }
    .c-grid__cell9--sp12 {
      width: 100%; } }
  @media only screen and (min-width: 769px) {
    .c-grid__cell10 {
      width: 83.33333%; }
      .c-grid__cell10--sp1 {
        width: 83.33333%; }
      .c-grid__cell10--sp2 {
        width: 83.33333%; }
      .c-grid__cell10--sp3 {
        width: 83.33333%; }
      .c-grid__cell10--sp4 {
        width: 83.33333%; }
      .c-grid__cell10--sp5 {
        width: 83.33333%; }
      .c-grid__cell10--sp6 {
        width: 83.33333%; }
      .c-grid__cell10--sp7 {
        width: 83.33333%; }
      .c-grid__cell10--sp8 {
        width: 83.33333%; }
      .c-grid__cell10--sp9 {
        width: 83.33333%; }
      .c-grid__cell10--sp10 {
        width: 83.33333%; }
      .c-grid__cell10--sp11 {
        width: 83.33333%; }
      .c-grid__cell10--sp12 {
        width: 83.33333%; } }
  @media only screen and (max-width: 768px) {
    .c-grid__cell10--sp1 {
      width: 8.33333%; }
    .c-grid__cell10--sp2 {
      width: 16.66667%; }
    .c-grid__cell10--sp3 {
      width: 25%; }
    .c-grid__cell10--sp4 {
      width: 33.33333%; }
    .c-grid__cell10--sp5 {
      width: 41.66667%; }
    .c-grid__cell10--sp6 {
      width: 50%; }
    .c-grid__cell10--sp7 {
      width: 58.33333%; }
    .c-grid__cell10--sp8 {
      width: 66.66667%; }
    .c-grid__cell10--sp9 {
      width: 75%; }
    .c-grid__cell10--sp10 {
      width: 83.33333%; }
    .c-grid__cell10--sp11 {
      width: 91.66667%; }
    .c-grid__cell10--sp12 {
      width: 100%; } }
  @media only screen and (min-width: 769px) {
    .c-grid__cell11 {
      width: 91.66667%; }
      .c-grid__cell11--sp1 {
        width: 91.66667%; }
      .c-grid__cell11--sp2 {
        width: 91.66667%; }
      .c-grid__cell11--sp3 {
        width: 91.66667%; }
      .c-grid__cell11--sp4 {
        width: 91.66667%; }
      .c-grid__cell11--sp5 {
        width: 91.66667%; }
      .c-grid__cell11--sp6 {
        width: 91.66667%; }
      .c-grid__cell11--sp7 {
        width: 91.66667%; }
      .c-grid__cell11--sp8 {
        width: 91.66667%; }
      .c-grid__cell11--sp9 {
        width: 91.66667%; }
      .c-grid__cell11--sp10 {
        width: 91.66667%; }
      .c-grid__cell11--sp11 {
        width: 91.66667%; }
      .c-grid__cell11--sp12 {
        width: 91.66667%; } }
  @media only screen and (max-width: 768px) {
    .c-grid__cell11--sp1 {
      width: 8.33333%; }
    .c-grid__cell11--sp2 {
      width: 16.66667%; }
    .c-grid__cell11--sp3 {
      width: 25%; }
    .c-grid__cell11--sp4 {
      width: 33.33333%; }
    .c-grid__cell11--sp5 {
      width: 41.66667%; }
    .c-grid__cell11--sp6 {
      width: 50%; }
    .c-grid__cell11--sp7 {
      width: 58.33333%; }
    .c-grid__cell11--sp8 {
      width: 66.66667%; }
    .c-grid__cell11--sp9 {
      width: 75%; }
    .c-grid__cell11--sp10 {
      width: 83.33333%; }
    .c-grid__cell11--sp11 {
      width: 91.66667%; }
    .c-grid__cell11--sp12 {
      width: 100%; } }
  @media only screen and (min-width: 769px) {
    .c-grid__cell12 {
      width: 100%; }
      .c-grid__cell12--sp1 {
        width: 100%; }
      .c-grid__cell12--sp2 {
        width: 100%; }
      .c-grid__cell12--sp3 {
        width: 100%; }
      .c-grid__cell12--sp4 {
        width: 100%; }
      .c-grid__cell12--sp5 {
        width: 100%; }
      .c-grid__cell12--sp6 {
        width: 100%; }
      .c-grid__cell12--sp7 {
        width: 100%; }
      .c-grid__cell12--sp8 {
        width: 100%; }
      .c-grid__cell12--sp9 {
        width: 100%; }
      .c-grid__cell12--sp10 {
        width: 100%; }
      .c-grid__cell12--sp11 {
        width: 100%; }
      .c-grid__cell12--sp12 {
        width: 100%; } }
  @media only screen and (max-width: 768px) {
    .c-grid__cell12--sp1 {
      width: 8.33333%; }
    .c-grid__cell12--sp2 {
      width: 16.66667%; }
    .c-grid__cell12--sp3 {
      width: 25%; }
    .c-grid__cell12--sp4 {
      width: 33.33333%; }
    .c-grid__cell12--sp5 {
      width: 41.66667%; }
    .c-grid__cell12--sp6 {
      width: 50%; }
    .c-grid__cell12--sp7 {
      width: 58.33333%; }
    .c-grid__cell12--sp8 {
      width: 66.66667%; }
    .c-grid__cell12--sp9 {
      width: 75%; }
    .c-grid__cell12--sp10 {
      width: 83.33333%; }
    .c-grid__cell12--sp11 {
      width: 91.66667%; }
    .c-grid__cell12--sp12 {
      width: 100%; } }

/* for hover effect */
/* for overlay */
/* for slider */
/* for accordion */
/* ==========================================================================
   Accordion
   ========================================================================== */
.c-accordion {
  /* 基本レイアウト */
  overflow: hidden; }
  .c-accordion__trigger {
    display: none; }
  .c-accordion__content {
    visibility: hidden;
    opacity: 0;
    height: 0;
    transform: translateY(-100%);
    transform-origin: top center;
    transition: all 0.5s cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: all 0.5s cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: opacity 0.5s cubic-bezier(0, 0, 0.25, 1), visibility 0.5s cubic-bezier(0, 0, 0.25, 1), padding 0.5s cubic-bezier(0, 0, 0.25, 1), transform 0.5s cubic-bezier(0, 0, 0.25, 1); }
  .c-accordion__trigger:checked + .c-accordion__content {
    visibility: visible;
    opacity: 1;
    height: auto;
    transform: none; }

/* for navigation */
.c-navigation {
  width: 100%;
  height: 100%;
  background-color: #ebe9dc;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  transform: translateY(-100%);
  transition: all 0.8s; }
  .c-navigation.active {
    transform: translateY(0%); }
  .c-navigation__list {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; }
  .c-navigation__item--01 {
    width: 28vw;
    margin: 0 auto 8vw; }
  .c-navigation__item--02 {
    width: 49.33333vw;
    margin: 0 auto 8vw; }
  .c-navigation__item--03 {
    width: 36vw;
    margin: 0 auto 8vw; }
  .c-navigation__item--04 {
    width: 48vw;
    margin: 0 auto 8vw; }
  .c-navigation__line {
    width: 89.33333vw;
    margin: 0 auto 8vw; }
  .c-navigation__close {
    width: 20.53333vw;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 18.66667vw; }
  .c-navigation__button {
    position: fixed;
    top: 3.73333vw;
    right: 8vw;
    text-align: center;
    cursor: pointer; }
  .c-navigation__icon {
    position: relative;
    margin-top: 3.5rem; }
    .c-navigation__icon, .c-navigation__icon::before, .c-navigation__icon::after {
      width: 2.5rem;
      height: 2px;
      border-radius: 6px;
      background-color: #3e3a39;
      display: inline-block; }
    .c-navigation__icon::before, .c-navigation__icon::after {
      content: "";
      position: absolute;
      left: 0;
      transition: all .2s; }
    .c-navigation__icon::before {
      top: -.8rem; }
    .c-navigation__icon::after {
      top: .8rem; }

/* for scroller */
/* for btn */
/* for link */
/* for inview */
/* for animation */
/* for nav */
/* for visual */
/* for title */
/* for box */
/* for card */
/* for agreement */
/* Project
   ----------------------------------------------------------------- */
/* for home */
/* ==========================================================================
   Visual
   ========================================================================== */
/* for mv */
.p-mv__inner {
  max-width: 114rem;
  margin: 0 auto; }

@media only screen and (min-width: 769px) {
  .p-mv__inner {
    padding-right: 4rem;
    padding-left: 4rem; } }

@media only screen and (max-width: 768px) {
  .p-mv__inner {
    padding-right: 1rem;
    padding-left: 1rem; } }

@media only screen and (min-width: 769px) {
  .p-mv__inner {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center; }
  .p-mv__video {
    position: relative;
    width: 100%;
    padding-top: 56.25%; }
    .p-mv__video video {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%; }
  .p-mv__mask {
    position: absolute;
    top: 0;
    left: -1%;
    background-image: url(../images/mask.png);
    z-index: 3;
    width: 103%;
    height: 100%;
    background-size: cover; }
    .p-mv__mask img {
      width: 100%;
      height: 100%; }
  .p-mv__sub {
    width: 18.30189%;
    margin-top: -5%;
    margin-bottom: 8rem;
    margin-left: 10%;
    margin-right: 3%;
    position: relative;
    z-index: 5; }
  .p-mv__text {
    width: 58.30189%;
    margin-top: -5%;
    margin-bottom: 8rem;
    position: relative;
    z-index: 5; } }

@media only screen and (min-width: 1200px) {
  .p-mv__sub {
    width: 19.4rem; }
  .p-mv__text {
    width: 61.8rem; } }

@media only screen and (max-width: 768px) {
  .p-mv__video {
    position: relative;
    width: 100%;
    padding-top: 56.25%; }
    .p-mv__video video {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%; }
  .p-mv__mask {
    position: absolute;
    top: -1%;
    left: -1%;
    background-image: url(../images/mask.png);
    z-index: 3;
    width: 103%;
    height: 103%;
    background-size: cover; }
    .p-mv__mask img {
      width: 100%;
      height: 100%; }
  .p-mv__ph {
    margin-left: -2.66667vw;
    margin-right: -2.66667vw; }
  .p-mv__text {
    width: 74.93333vw;
    margin: 0 auto 6.66667vw; }
  .p-mv__sub {
    width: 34.4vw;
    margin: 0 auto 8.53333vw; } }

/* for about */
.p-about__inner {
  max-width: 114rem;
  margin: 0 auto; }

@media only screen and (min-width: 769px) {
  .p-about__inner {
    padding-right: 4rem;
    padding-left: 4rem; } }

@media only screen and (max-width: 768px) {
  .p-about__inner {
    padding-right: 1rem;
    padding-left: 1rem; } }

@media only screen and (min-width: 769px) {
  .p-about {
    margin-bottom: 12rem; }
    .p-about__inner {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center; }
    .p-about__descBox {
      width: 50%;
      padding-right: 6rem; }
      .p-about__descBoxInner {
        width: 32rem;
        margin: 0 0 0 auto; }
    .p-about__title {
      margin-bottom: 6rem;
      width: 15.9rem; }
    .p-about__text {
      width: 32rem;
      line-height: 3rem; }
      .p-about__text p {
        margin-bottom: 4rem; }
    .p-about__phBox {
      width: 50%; }
      .p-about__phBox img {
        margin: 0 auto;
        width: 42.2rem; } }

@media only screen and (max-width: 768px) {
  .p-about__title {
    width: 47.2vw;
    margin: 0 auto; }
  .p-about__phBox {
    width: 55.73333vw;
    margin: 6.13333vw auto 8.26667vw; }
    .p-about__phBox img {
      margin: 0 auto; }
  .p-about__text {
    width: 85.33333vw;
    margin: 0 auto;
    font-size: 3.46667vw; }
    .p-about__text p {
      margin-bottom: 8.26667vw; } }

/* for system */
.p-system {
  opacity: 0; }
  @media only screen and (min-width: 769px) {
    .p-system {
      margin-bottom: 34rem; }
      .p-system__inner {
        max-width: 114rem;
        margin: 0 auto; } }
  @media only screen and (min-width: 769px) and (min-width: 769px) {
    .p-system__inner {
      padding-right: 4rem;
      padding-left: 4rem; } }
  @media only screen and (min-width: 769px) and (max-width: 768px) {
    .p-system__inner {
      padding-right: 1rem;
      padding-left: 1rem; } }
  @media only screen and (min-width: 769px) {
      .p-system__inner {
        position: relative; }
      .p-system__title {
        width: 26.7rem;
        margin: 0 auto 8.6rem; }
      .p-system__ph--01 {
        width: 29.71698%;
        position: absolute;
        top: -10rem;
        left: 2.4rem; }
      .p-system__ph--02 {
        width: 27.07547%;
        position: absolute;
        top: -6rem;
        right: 2.4rem; }
      .p-system__heading {
        width: 14.6rem;
        margin: 0 auto 5rem; }
      .p-system__order {
        margin-left: 7.4rem;
        margin-bottom: 10.8rem; }
        .p-system__orderItem {
          display: flex;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: flex-start;
          margin-bottom: 1.6rem; }
        .p-system__orderNumber--01 {
          width: 2.3rem; }
        .p-system__orderNumber--02 {
          width: 2.4rem; }
        .p-system__orderNumber--03 {
          width: 2.4rem; }
        .p-system__orderNumber--04 {
          width: 2.5rem; }
        .p-system__orderText {
          margin-left: 1.6rem;
          font-size: 1.4rem; }
          .p-system__orderText span {
            font-weight: bold; }
      .p-system__course {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: flex-start; }
        .p-system__courseItem {
          width: 50%; }
        .p-system__courseHeading {
          margin: 0 auto 1.5rem; }
          .p-system__courseHeading--01 {
            width: 16.6rem; }
          .p-system__courseHeading--02 {
            width: 26.3rem; }
        .p-system__coursePic {
          margin: 0 auto; }
          .p-system__coursePic--01 {
            width: 84.33962%; }
          .p-system__coursePic--02 {
            width: 86.79245%; }
        .p-system__courseDesc {
          float: left;
          margin-top: 1rem;
          margin-left: 15.09434%;
          font-size: 1.2037vw; }
          .p-system__courseDesc--first {
            margin-left: 0; }
          .p-system__courseDesc--last {
            margin-left: 7.54717%; }
          .p-system__courseDesc--last02 {
            margin-left: 7.54717%; }
      .p-system__menu {
        position: relative; }
        .p-system__menuBg {
          width: 96.03%;
          margin: 7rem auto 0; }
        .p-system__menuItem {
          display: flex;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center; }
          .p-system__menuItem--01 {
            position: absolute;
            top: 11.50943%;
            left: 10.18868%; }
          .p-system__menuItem--02 {
            position: absolute;
            top: 11.50943%;
            right: 7.35849%; }
          .p-system__menuItem--03 {
            position: absolute;
            bottom: 11.50943%;
            left: 10.18868%; }
          .p-system__menuItem--04 {
            position: absolute;
            bottom: 19.0566%;
            right: 12.45283%; }
        .p-system__menuHeading {
          margin-right: 2rem; }
          .p-system__menuHeading--01 {
            width: 8.49057vw; }
          .p-system__menuHeading--02 {
            width: 8.77358vw; }
          .p-system__menuHeading--03 {
            width: 8.96226vw; }
          .p-system__menuHeading--04 {
            width: 6.50943vw;
            margin-right: 4.6rem; }
        .p-system__menuBody {
          display: flex;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: flex-start;
          font-size: 1.22642vw;
          line-height: 2.83019vw; }
          .p-system__menuBodyLeft {
            margin-right: 2rem; }
        .p-system__menuPic {
          width: 5.84906%;
          position: absolute;
          bottom: 11.50943%;
          right: 0.18868%; }
        .p-system__menuPh--01 {
          width: 18.96226%;
          position: absolute;
          left: 3.58491%;
          bottom: -26.41509%; }
        .p-system__menuPh--02 {
          width: 16.88679%;
          position: absolute;
          left: 30.9434%;
          bottom: -33.01887%; }
        .p-system__menuPh--03 {
          width: 15.4717%;
          position: absolute;
          right: 30.9434%;
          bottom: -31.13208%; }
        .p-system__menuPh--04 {
          width: 19.15094%;
          position: absolute;
          right: 4.5283%;
          bottom: -32.07547%; }
        .p-system__menuNote {
          position: absolute;
          right: 0;
          bottom: -20rem; } }
  @media only screen and (min-width: 1200px) {
    .p-system__courseDesc {
      font-size: 1.3rem; }
      .p-system__courseDesc--last {
        margin-left: 15.09434%; }
      .p-system__courseDesc--last02 {
        margin-left: 11.32075%; }
    .p-system__menu {
      position: relative; }
      .p-system__menuBg {
        width: 96.03%;
        margin: 7rem auto 0; }
      .p-system__menuItem {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center; }
        .p-system__menuItem--01 {
          position: absolute;
          top: 7.2rem;
          left: 14.8rem; }
        .p-system__menuItem--02 {
          position: absolute;
          top: 7.2rem;
          right: 13.8rem; }
        .p-system__menuItem--03 {
          position: absolute;
          bottom: 7.2rem;
          left: 14.8rem; }
        .p-system__menuItem--04 {
          position: absolute;
          bottom: 10.2rem;
          right: 18.8rem; }
      .p-system__menuHeading {
        margin-right: 2rem; }
        .p-system__menuHeading--01 {
          width: 9rem; }
        .p-system__menuHeading--02 {
          width: 9.3rem; }
        .p-system__menuHeading--03 {
          width: 9.5rem; }
        .p-system__menuHeading--04 {
          width: 6.9rem;
          margin-right: 4.6rem; }
      .p-system__menuBody {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 1.3rem;
        line-height: 3rem; }
        .p-system__menuBodyLeft {
          margin-right: 2rem; } }
  @media only screen and (max-width: 768px) {
    .p-system {
      margin-bottom: 90.66667vw; }
      .p-system__title {
        width: 53.6vw;
        margin: 0 auto 7.2vw; }
      .p-system__phBox {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center; }
      .p-system__ph--01 {
        width: 46.13333vw; }
      .p-system__ph--02 {
        width: 44vw; }
      .p-system__heading {
        width: 36.53333vw;
        margin: 4.26667vw auto 7.2vw; }
      .p-system__order {
        padding: 0 7.46667vw;
        margin-bottom: 12vw; }
        .p-system__orderItem {
          display: flex;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: flex-start;
          margin-bottom: 4.26667vw; }
        .p-system__orderNumber--01 {
          width: 5.33333vw; }
        .p-system__orderNumber--02 {
          width: 5.86667vw; }
        .p-system__orderNumber--03 {
          width: 5.86667vw; }
        .p-system__orderNumber--04 {
          width: 5.86667vw; }
        .p-system__orderText {
          margin-left: 2.66667vw;
          font-size: 3.46667vw;
          line-height: 5.33333vw; }
          .p-system__orderText span {
            font-weight: bold; }
      .p-system__course {
        padding: 0 7.46667vw; }
        .p-system__courseItem {
          margin-bottom: 12vw; }
        .p-system__courseHeading {
          margin: 0 auto 4vw; }
          .p-system__courseHeading--01 {
            width: 29.6vw; }
          .p-system__courseHeading--02 {
            width: 47.2vw; }
        .p-system__coursePic {
          margin: 0 auto; }
          .p-system__coursePic--01 {
            width: 79.46667vw; }
          .p-system__coursePic--02 {
            width: 83.46667vw; }
        .p-system__courseDesc {
          text-align: center;
          margin-top: 2.66667vw;
          font-size: 3.2vw; }
          .p-system__courseDescBox {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: flex-start; }
      .p-system__menu {
        position: relative; }
        .p-system__menuItem {
          display: flex;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;
          padding: 0 7.46667vw;
          margin-top: 4.26667vw;
          margin-bottom: 4.26667vw; }
          .p-system__menuItem--04 {
            margin-bottom: 20vw; }
        .p-system__menuHeading {
          margin-right: 5.33333vw; }
          .p-system__menuHeading--01 {
            width: 19.73333vw; }
          .p-system__menuHeading--02 {
            width: 19.73333vw; }
          .p-system__menuHeading--03 {
            width: 20vw; }
          .p-system__menuHeading--04 {
            width: 15.2vw;
            margin-right: 10.13333vw; }
        .p-system__menuBody {
          display: flex;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: flex-start;
          font-size: 2.93333vw;
          line-height: 5.33333vw; }
          .p-system__menuBodyLeft {
            margin-right: 2.66667vw; }
        .p-system__menuLineInner {
          padding: 0 7.46667vw; }
        .p-system__menuPic {
          width: 12.26667vw;
          position: absolute;
          right: 4.26667vw;
          bottom: 13.33333vw; }
        .p-system__menuPh--01 {
          width: 30.93333vw;
          position: absolute;
          left: 15.46667vw;
          bottom: -46.93333vw; }
        .p-system__menuPh--02 {
          width: 27.46667vw;
          position: absolute;
          right: 13.33333vw;
          bottom: -46.93333vw; }
        .p-system__menuPh--03 {
          width: 25.06667vw;
          position: absolute;
          left: 15.46667vw;
          bottom: -12.26667vw; }
        .p-system__menuPh--04 {
          width: 30.93333vw;
          position: absolute;
          right: 13.33333vw;
          bottom: -12.26667vw; }
        .p-system__menuNote {
          padding: 0 7.46667vw;
          font-size: 3.2vw;
          position: absolute;
          bottom: -65.6vw; } }

/* for service */
.p-service {
  opacity: 0; }
  .p-service__inner {
    max-width: 114rem;
    margin: 0 auto; }
  @media only screen and (min-width: 769px) {
    .p-service__inner {
      padding-right: 4rem;
      padding-left: 4rem; } }
  @media only screen and (max-width: 768px) {
    .p-service__inner {
      padding-right: 1rem;
      padding-left: 1rem; } }
  @media only screen and (min-width: 769px) {
    .p-service {
      margin-bottom: 15rem; }
      .p-service__inner {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-start; }
      .p-service__descBox {
        padding-top: 21.69811%; }
      .p-service__title {
        margin-left: 5.4717vw;
        margin-bottom: 5.09434vw;
        width: 15.4717vw; }
      .p-service__description {
        margin-bottom: 7.16981vw;
        line-height: 2.83019vw; }
        .p-service__description img {
          margin-bottom: 2.26415vw; }
        .p-service__description--01 {
          margin-left: 5.4717vw; }
          .p-service__description--01 img {
            width: 27.83019vw; }
        .p-service__description--02 {
          margin-left: 11.32075vw; }
          .p-service__description--02 img {
            width: 21.79245vw; }
        .p-service__description--03 {
          margin-left: 3.58491vw; }
          .p-service__description--03 img {
            width: 24.90566vw; }
      .p-service__text {
        width: 36.13208vw;
        margin-left: -2.8rem; } }
  @media only screen and (min-width: 1200px) {
    .p-service__title {
      width: 16.4rem; }
    .p-service__description {
      margin-bottom: 7.6rem;
      line-height: 3rem; }
      .p-service__description--01 img {
        width: 29.5rem; }
      .p-service__description--02 img {
        width: 23.1rem; }
      .p-service__description--03 img {
        width: 26.4rem; }
    .p-service__text {
      width: 38.3rem; } }
  @media only screen and (max-width: 768px) {
    .p-service__title {
      width: 33.33333vw;
      margin: 0 auto;
      margin-bottom: 5.6vw; }
    .p-service__phBox {
      margin-bottom: 10.13333vw; }
    .p-service__description {
      margin-left: 10.13333vw;
      margin-bottom: 11.2vw;
      font-size: 3.46667vw; }
      .p-service__description img {
        margin-bottom: 3.2vw; }
      .p-service__description p {
        width: 74.66667vw; }
      .p-service__description--01 img {
        width: 52.8vw; }
      .p-service__description--02 img {
        width: 41.06667vw; }
      .p-service__description--03 img {
        width: 45.6vw; }
    .p-service__text img {
      width: 87.2vw;
      margin: 0 auto 16.26667vw; } }

/* for menu */
.p-menu {
  opacity: 0; }
  .p-menu__inner {
    max-width: 114rem;
    margin: 0 auto; }
  @media only screen and (min-width: 769px) {
    .p-menu__inner {
      padding-right: 4rem;
      padding-left: 4rem; } }
  @media only screen and (max-width: 768px) {
    .p-menu__inner {
      padding-right: 1rem;
      padding-left: 1rem; } }
  @media only screen and (min-width: 769px) {
    .p-menu {
      margin-bottom: 12rem; }
      .p-menu__title img {
        width: 22.8rem;
        margin: 0 auto 4.2rem; }
      .p-menu__descBox {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-start;
        margin-bottom: 11rem; }
        .p-menu__descBoxLeft {
          width: 45.6%; }
          .p-menu__descBoxLeft img {
            width: 6.3rem;
            margin: 0 auto 2.8rem; }
          .p-menu__descBoxLeft p {
            width: 32rem;
            margin: 0 auto;
            line-height: 3rem; }
        .p-menu__descBoxRight {
          width: 45.6%; }
          .p-menu__descBoxRight img {
            width: 5.5rem;
            margin: 0 auto 2.8rem; }
          .p-menu__descBoxRight p {
            width: 23rem;
            margin: 0 auto;
            line-height: 3rem; }
      .p-menu__heading img {
        width: 14rem;
        margin: 0 auto; }
      .p-menu__phBox {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start; }
        .p-menu__phBox img {
          display: none; }
      .p-menu__ph--01 {
        width: 25.75472%;
        margin-right: 2.92453%;
        margin-top: 2.16981%; }
      .p-menu__ph--02 {
        width: 20.09434%;
        margin-right: 4.71698%;
        margin-top: 6.03774%; }
      .p-menu__ph--03 {
        width: 20.56604%;
        margin-right: 5.66038%; }
      .p-menu__ph--04 {
        width: 20.28302%;
        margin-top: 2.64151%; }
      .p-menu__ph--05 {
        width: 20.09434%;
        margin-left: 10.75472%;
        margin-right: 8.49057%;
        margin-top: 3.01887%; }
      .p-menu__ph--06 {
        width: 20.56604%;
        margin-right: 7.26415%;
        margin-top: 6.60377%; }
      .p-menu__ph--07 {
        width: 24.15094%;
        margin-top: 5.18868%; } }
  @media only screen and (max-width: 768px) {
    .p-menu__title img {
      width: 58.93333vw;
      margin: 0 auto 4.8vw; }
    .p-menu__descBox {
      font-size: 3.2vw;
      text-align: center; }
      .p-menu__descBox img {
        margin: 0 auto 4.53333vw; }
      .p-menu__descBoxLeft {
        margin-bottom: 10.13333vw; }
        .p-menu__descBoxLeft img {
          width: 18.13333vw; }
      .p-menu__descBoxRight {
        margin-bottom: 16.26667vw; }
        .p-menu__descBoxRight img {
          width: 13.06667vw; }
    .p-menu__heading img {
      width: 67.73333vw;
      margin: 0 auto 7.46667vw; }
    .p-menu__phBox {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0 auto 17.33333vw; }
    .p-menu__ph--01 {
      width: 47.46667vw;
      margin-right: 5.06667vw; }
    .p-menu__ph--02 {
      width: 37.06667vw;
      margin-top: 8vw; }
    .p-menu__ph--03 {
      width: 38.13333vw;
      margin-top: 1.6vw;
      margin-left: 3.73333vw;
      margin-right: 9.86667vw; }
    .p-menu__ph--04 {
      width: 37.33333vw;
      margin-top: 6.13333vw; }
    .p-menu__ph--05 {
      width: 37.06667vw;
      margin-top: 11.2vw;
      margin-left: 5.6vw;
      margin-right: 9.33333vw; }
    .p-menu__ph--06 {
      width: 38.13333vw;
      margin-top: 6.13333vw; }
    .p-menu__ph--07 {
      width: 44.53333vw;
      margin-top: 8vw;
      margin-left: 24.8vw; } }

/* for ig */
.p-ig {
  opacity: 0; }
  .p-ig__inner {
    max-width: 114rem;
    margin: 0 auto; }
  @media only screen and (min-width: 769px) {
    .p-ig__inner {
      padding-right: 4rem;
      padding-left: 4rem; } }
  @media only screen and (max-width: 768px) {
    .p-ig__inner {
      padding-right: 1rem;
      padding-left: 1rem; } }
  .p-ig__inner {
    position: relative; }
  @media only screen and (min-width: 769px) {
    .p-ig__title img {
      width: 19.8rem;
      margin: 0 auto 6rem; }
    .p-ig__feed {
      width: 495px;
      margin: 0 auto 14rem; }
    .p-ig__gallery {
      display: inline-block;
      margin: 0.8rem 1rem; }
      .p-ig__gallery img {
        width: 14.5rem; }
    .p-ig__text {
      width: 17.1rem;
      position: absolute;
      left: 20rem;
      bottom: -7rem; } }
  @media only screen and (max-width: 768px) {
    .p-ig__title img {
      width: 39.46667vw;
      margin: 0 auto 6.66667vw; }
    .p-ig__feed {
      width: 100%;
      margin: 0 auto 30.66667vw;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center; }
    .p-ig__gallery {
      width: 30%;
      margin-bottom: 2.66667vw; }
    .p-ig__text {
      width: 38.13333vw;
      position: absolute;
      right: 2.66667vw;
      bottom: -16vw; } }

/* for information */
.p-information {
  opacity: 0; }
  .p-information__inner {
    max-width: 114rem;
    margin: 0 auto; }
  @media only screen and (min-width: 769px) {
    .p-information__inner {
      padding-right: 4rem;
      padding-left: 4rem; } }
  @media only screen and (max-width: 768px) {
    .p-information__inner {
      padding-right: 1rem;
      padding-left: 1rem; } }
  .p-information__map {
    position: relative;
    height: 0;
    overflow: hidden; }
    .p-information__map iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important; }
  @media only screen and (min-width: 769px) {
    .p-information__inner {
      position: relative; }
    .p-information__title {
      margin-left: -3.2rem; }
      .p-information__title img {
        width: 29.3rem;
        margin: 0 auto 6rem; }
    .p-information__desc {
      text-align: center;
      margin-bottom: 6rem;
      font-size: 1.6rem;
      line-height: 3.7rem; }
    .p-information__box {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      flex-direction: column; }
    .p-information__map {
      width: 40rem;
      height: 40rem;
      border-radius: 10px;
      padding-right: 1.8rem; }
    .p-information__hours p {
      text-align: center;
      margin-bottom: 6rem;
      font-size: 1.4rem;
      line-height: 2.4rem;
      font-weight: bold; }
    .p-information__text {
      width: 20.8rem; } }
  @media only screen and (max-width: 768px) {
    .p-information__inner {
      position: relative; }
    .p-information__title {
      margin-left: -5.33333vw; }
      .p-information__title img {
        width: 59.46667vw;
        margin: 0 auto 10.66667vw; }
    .p-information__desc {
      text-align: center;
      margin-bottom: 10.66667vw;
      font-size: 3.46667vw;
      line-height: 6.66667vw; }
    .p-information__map {
      padding-bottom: 53.33%;
      /*750:450*/
      margin-left: -1rem;
      margin-right: -1rem; }
    .p-information__hours p {
      margin-bottom: 10.66667vw;
      text-align: center;
      font-size: 2.93333vw;
      line-height: 6.4vw;
      font-weight: bold; }
    .p-information__text {
      width: 41.86667vw;
      margin: 0 auto; } }

/* Utility
   ----------------------------------------------------------------- */
/*
 * displayプロパティに関するutilityのクラスです
 */
@media only screen and (min-width: 769px) {
  .u-dsp-sp {
    display: none !important; } }

@media only screen and (max-width: 768px) {
  .u-dsp-pc {
    display: none !important; } }

/*
 * hover状態に関するutilityのクラスです
 */
.u-hov-alpha {
  transition: all 0.25s cubic-bezier(0, 0, 0.25, 1) 0s; }
  .u-hov-alpha:hover {
    opacity: .7; }

.u-hov-circle {
  position: relative;
  display: inline-block;
  line-height: 1; }
  .u-hov-circle:before {
    position: absolute;
    content: '';
    display: block;
    visibility: hidden;
    opacity: 0;
    width: 1em;
    height: 1em;
    left: -2em;
    border: 1px solid #333;
    border-radius: 50%;
    transform: scale(0);
    transition: all 0.25s cubic-bezier(0, 0, 0.25, 1) 0s; }
  .u-hov-circle:hover:before {
    visibility: visible;
    opacity: 1;
    transform: none; }

.u-hov-marker {
  position: relative;
  display: inline-block; }
  .u-hov-marker:before {
    content: '';
    position: absolute;
    display: block;
    width: calc(100% + 2.2em);
    height: .5em;
    left: -1.1em;
    bottom: 0.22em;
    background: #666;
    z-index: -1;
    transform: scaleX(0);
    transition: all 0.25s cubic-bezier(0, 0, 0.25, 1) 0s; }
  .u-hov-marker:hover:before {
    transform: none; }

/*
 * 装飾に関するutilityのクラスです
 */
@media only screen and (max-width: 768px) {
  .u-deco-shadow {
    position: relative; }
    .u-deco-shadow:before {
      content: '';
      position: absolute;
      display: block;
      width: 100vw;
      height: 1.5rem;
      top: 0;
      left: 0;
      background: linear-gradient(0deg, rgba(51, 51, 51, 0) 0%, rgba(51, 51, 51, 0.02) 29.4%, rgba(51, 51, 51, 0.06) 56.2%, rgba(51, 51, 51, 0.13) 81.91%, rgba(51, 51, 51, 0.2) 100%); } }
