.c-navigation{
  width: 100%;
  height: 100%;
  background-color: $col_bg;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  transform: translateY(-100%);
  transition: all 0.8s;
  &.active{
    transform: translateY(0%);
  }
  &__list{
    @include centerSet;
    width: 100%;
  }
  &__item{
    &--01{
      width: makeVw(105);
      margin: 0 auto makeVw(30);
    }
    &--02{
      width: makeVw(185);
      margin: 0 auto makeVw(30);
    }
    &--03{
      width: makeVw(135);
      margin: 0 auto makeVw(30);
    }
    &--04{
      width: makeVw(180);
      margin: 0 auto makeVw(30);
    }
  }
  &__line{
    width: makeVw(335);
    margin: 0 auto makeVw(30);
  }
  &__close{
    width: makeVw(77);
    @include centerSet(x);
    bottom: makeVw(70);
  }

  &__button {
    position: fixed;
    top: makeVw(14);
    right: makeVw(30);
    text-align: center;
    cursor: pointer;
  }

  &__icon {
    position: relative;
    margin-top: 3.5rem;

    &,
    &::before,
    &::after {
      width: 2.5rem;
      height: 2px;
      border-radius: 6px;
      background-color: #3e3a39;
      display: inline-block;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      transition: all .2s;
    }

    &::before { top: -.8rem; }
    &::after { top: .8rem; }
  }
}