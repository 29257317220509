/* ==========================================================================
   Font Face
   ========================================================================== */

/* Font Face mixin
   ----------------------------------------------------------------- */
@mixin fontFace($name, $path, $weight: 400, $style: normal, $exts: woff2 woff){
  $src: null;

  $formats: (
    woff2: 'woff2',
    woff: 'woff',
  );

  @each $ext in $exts {
    $format: map-get($formats, $ext);
    $src: append($src, url(quote($path + '.' + $ext)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style:  $style;
    font-display: block;
    font-weight: $weight;
    src: $src;
  }
}

/* Include Noto Sans
   ----------------------------------------------------------------- */
@include fontFace('Noto Sans JP', '../fonts/NotoSansCJKjp-DemiLight.min', 300);
@include fontFace('Noto Sans JP', '../fonts/NotoSansCJKjp-Regular.min');
@include fontFace('Noto Sans JP', '../fonts/NotoSansCJKjp-Medium.min', 500);

/* Include Noto Serif
   ----------------------------------------------------------------- */
@include fontFace('Noto Serif Japanese', '../fonts/NotoSerifCJKjp-Medium.min', 500);
@include fontFace('Noto Serif Japanese', '../fonts/NotoSerifCJKjp-Bold.min', 700);

//えり字
@font-face{ 
	font-family: 'eriji';
	src: url('../../fonts/eriji.woff') format('woff'), /* モダンブラウザ用 */
		url('../../fonts/eriji.otf') format('opentype');
}


/* ==========================================================================
   Family
   ========================================================================== */

/* Main
   ----------------------------------------------------------------- */
$font_main: 'M+ Type-2 (general-j) Regular', sans-serif;

/* Serif
   ----------------------------------------------------------------- */
$font_serif: 'Noto Serif JP', serif;
