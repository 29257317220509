.p-menu{
  @include basicLayout;
  opacity: 0;
  @include pc{
    margin-bottom: makeRem(120);
    &__title{
      img{
        width: makeRem(228);
        margin: 0 auto makeRem(42);
      }
    }
    &__descBox{
      @include flexLayout(nowrap, center, flex-start);
      margin-bottom: makeRem(110);
      &Left{
        width: 45.6%;
        img{
          width: makeRem(63);
          margin: 0 auto makeRem(28);
        }
        p{
          width: makeRem(320);
          margin: 0 auto;
          line-height: makeRem(30);
        }
      }
      &Right{
        width: 45.6%;
        img{
          width: makeRem(55);
          margin: 0 auto makeRem(28);
        }
        p{
          width: makeRem(230);
          margin: 0 auto;
          line-height: makeRem(30);
        }
      }
    }
    &__heading{
      img{
        width: makeRem(140);
        margin: 0 auto;
      }
    }
    &__ph{
      &Box{
        @include flexLayout(wrap, flex-start, flex-start);
        img{
          display: none;
        }       
      }
      &--01{
        width: makePer(273);
        margin-right: makePer(31);
        margin-top: makePer(23);
      }
      &--02{
        width: makePer(213);
        margin-right: makePer(50);
        margin-top: makePer(64);
      }
      &--03{
        width: makePer(218);
        margin-right: makePer(60);
      }
      &--04{
        width: makePer(215);
        margin-top: makePer(28);
      }
      &--05{
        width: makePer(213);
        margin-left: makePer(114);
        margin-right: makePer(90);
        margin-top: makePer(32);
      }
      &--06{
        width: makePer(218);
        margin-right: makePer(77);
        margin-top: makePer(70);
      }
      &--07{
        width: makePer(256);
        margin-top: makePer(55);
      }
      
    }

  }

  @include sp{
    &__title{
      img{
        width: makeVw(221);
        margin: 0 auto makeVw(18);
      }
    }
    &__descBox{
      font-size: makeVw(24,750);
      text-align: center;
      img{
        margin: 0 auto makeVw(17);
      }
      &Left{
        margin-bottom: makeVw(38);
        img{
          width: makeVw(68);
        }
      }
      &Right{
        margin-bottom: makeVw(61);
        img{
          width: makeVw(49);
        }
      }
    }
    &__heading{
      img{
        width: makeVw(254);
        margin: 0 auto makeVw(28);
      }
    }
    &__ph{
      &Box{
        @include flexLayout(wrap, flex-start, flex-start);
        margin: 0 auto makeVw(65);
      }
      &--01{
        width: makeVw(178);
        margin-right: makeVw(19);
      }
      &--02{
        width: makeVw(139);
        margin-top: makeVw(30);
      }
      &--03{
        width: makeVw(143);
        margin-top: makeVw(6);
        margin-left: makeVw(14);
        margin-right: makeVw(37);
      }
      &--04{
        width: makeVw(140);
        margin-top: makeVw(23);
      }
      &--05{
        width: makeVw(139);
        margin-top: makeVw(42);
        margin-left: makeVw(21);
        margin-right: makeVw(35);
      }
      &--06{
        width: makeVw(143);
        margin-top: makeVw(23);
      }
      &--07{
        width: makeVw(167);
        margin-top: makeVw(30);
        margin-left: makeVw(93);
      }
    }

  }
}