/*
 * hover状態に関するutilityのクラスです
 */
$prefix: 'u-hov';

.#{$prefix}{
  &-alpha{
    @include alphaBtn;
  }
  &-circle{
    position: relative;
    display: inline-block;
    line-height: 1;
    &:before{
      position: absolute;
      content: '';
      display: block;
      visibility: hidden;
      opacity: 0;
      width: 1em;
      height: 1em;
      left: -2em;
      border: 1px solid $col_black;
      border-radius: 50%;
      transform: scale(0);
      @include transAnime(.25s);
    }
    &:hover{
      &:before{
        visibility: visible;
        opacity: 1;
        transform: none;
      }
    }
  }
  &-marker{
    position: relative;
    display: inline-block;
    &:before{
      $offset: 2.2em;
      content: '';
      position: absolute;
      display: block;
      width: calc(100% + #{$offset});
      height: .5em;
      left: -$offset / 2;
      bottom: $offset / 10;
      background: $col_key;
      z-index: -1;
      transform: scaleX(0);
      @include transAnime(.25s);
    }
    &:hover{
      &:before{
        transform: none;
      }
    }
  }
}
