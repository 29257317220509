.p-mv{
  @include basicLayout;
  @include pc{
    &__inner{
      position: relative;
      @include flexLayout(wrap, flex-start, center);
    }
    &__video{
      position: relative;
      width:100%;
      padding-top:56.25%;
      video{
        position:absolute;
        top:0;
        right:0;
        width:100%;
        height:100%;
      }
    }
    &__mask{
      position: absolute;
      top: 0;
      left: -1%;
      background-image: url(../images/mask.png);
      z-index: 3;
      width: 103%;
      height: 100%;
      background-size: cover;
      img{
        width: 100%;
        height: 100%;
      }
    }
    &__sub{
      width: makePer(194);
      margin-top: -5%;
      margin-bottom: makeRem(80);
      margin-left: 10%;
      margin-right: 3%;
      position: relative;
      z-index: 5;
    }
    &__text{
      width: makePer(618);
      margin-top: -5%;
      margin-bottom: makeRem(80);
      position: relative;
      z-index: 5;
    }
    
  }

  @include pcL{
    &__sub{
      width: makeRem(194);
    }
    &__text{
      width: makeRem(618);
    }
  }

  @include sp{
    &__video{
      position: relative;
      width:100%;
      padding-top:56.25%;
      video{
        position:absolute;
        top:0;
        right:0;
        width:100%;
        height:100%;
      }
    }
    &__mask{
      position: absolute;
      top: -1%;
      left: -1%;
      background-image: url(../images/mask.png);
      z-index: 3;
      width: 103%;
      height: 103%;
      background-size: cover;
      img{
        width: 100%;
        height: 100%;
      }
    }
    &__ph{
      margin-left: -(makeVw(10));
      margin-right: -(makeVw(10));
    }
    &__text{
      width: makeVw(281);
      margin: 0 auto makeVw(25);
    }
    &__sub{
      width: makeVw(129);
      margin: 0 auto makeVw(32);
    }
  }
}