.p-about{
  @include basicLayout;
  @include pc{
    margin-bottom: makeRem(120);
    &__inner{
      @include flexLayout(nowrap, center, center);
    }
    &__descBox{
      width: 50%;
      padding-right: makeRem(60);
      &Inner{
        width: makeRem(320);
        margin: 0 0 0 auto;
      }
    }
    &__title{
      margin-bottom: makeRem(60);
      width: makeRem(159);
    }
    &__text{
      width: makeRem(320);
      line-height: makeRem(30);
      p{
        margin-bottom: makeRem(40);
      }
    }
    &__phBox{
      width: 50%;
      img{
        margin: 0 auto;
        width: makeRem(422);
      }
    }
  }
  
  @include sp{
    &__title{
      width: makeVw(177);
      margin: 0 auto;
    }
    &__phBox{
      width: makeVw(209);
      margin: makeVw(23) auto makeVw(31);
      img{
        margin: 0 auto;
      }
    }
    &__text{
      width: makeVw(320);
      margin: 0 auto;
      font-size: makeVw(26,750);
      p{
        margin-bottom: makeVw(31);
      }
    }
    
  }

}