/* ==========================================================================
   Section
   ========================================================================== */
.l-section{
  @include section;

  /* SP no padding */
  &--spFull{
    @include section;
    @include sp {
      max-width: none;
      &__inner{
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}
