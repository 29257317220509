/* ==========================================================================
   My Reset
   ========================================================================== */
*{
  margin: 0;
  padding: 0;
}
@for $i from 1 through 6 {
  h#{$i}{
    font-size: 1em;
    font-weight: 400;
    margin: 0;
  }
}
ul, ol{
  li{
    list-style: none;
  }
}
a{
  color: $col_black;
  text-decoration: none;
  outline: none;
  &:link, &:active, &:hover, &:visited{
  }
}
img{
  display: block;
  max-width: 100%;
  height: auto;
}
input, button, textarea, select {
  appearance: none;
  outline: none;
}
button{
  cursor: pointer;
}
address{
  font-style: normal;
}
