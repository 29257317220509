.p-service{
  @include basicLayout;
  opacity: 0;
  @include pc{
    margin-bottom: makeRem(150);
    &__inner{
      @include flexLayout(nowrap, center, flex-start);
    }
    &__phBox{
      
    }
    &__descBox{
      padding-top: makePer(230);
    }
    &__title{
      margin-left: makeVw(58,1060);
      margin-bottom: makeVw(54,1060);
      width: makeVw(164,1060);
    }
    &__description{
      margin-bottom: makeVw(76,1060);
      line-height: makeVw(30,1060);
      img{
        margin-bottom: makeVw(24,1060);
      }
      &--01{
        margin-left: makeVw(58,1060);
        img{
          width: makeVw(295,1060);
        }
      }
      &--02{
        margin-left: makeVw(120,1060);
        img{
          width: makeVw(231,1060);
        }
      }
      &--03{
        margin-left: makeVw(38,1060);
        img{
          width: makeVw(264,1060);
        }
      }
    }
    &__text{
      width: makeVw(383,1060);
      margin-left: -(makeRem(28));
    }
  }

  @include pcL{
    &__title{
      width: makeRem(164);
    }
    &__description{
      margin-bottom: makeRem(76);
      line-height: makeRem(30);
      &--01{
        img{
          width: makeRem(295);
        }
      }
      &--02{
        img{
          width: makeRem(231);
        }
      }
      &--03{
        img{
          width: makeRem(264);
        }
      }
    }
    &__text{
      width: makeRem(383);
    }
  }

  @include sp{
    &__title{
      width: makeVw(125);
      margin: 0 auto;
      margin-bottom: makeVw(21);
    }
    &__phBox{
      margin-bottom: makeVw(38);
    }
    &__description{
      margin-left: makeVw(38);
      margin-bottom: makeVw(42);
      font-size: makeVw(26,750);
      img{
        margin-bottom: makeVw(12);
      }
      p{
        width: makeVw(280);
      }
      &--01{
        img{
          width: makeVw(198);
        }
      }
      &--02{
        img{
          width: makeVw(154);
        }
      }
      &--03{
        img{
          width: makeVw(171);
        }
      }
    }
    &__text{
      img{
        width: makeVw(327);
        margin: 0 auto makeVw(61);
      }
    }
  }
  
}