/*
 * 装飾に関するutilityのクラスです
 */
$prefix: 'u-deco';

.#{$prefix}{
  &-shadow{
    @include sp {
      position: relative;
      &:before{
        content: '';
        position: absolute;
        display: block;
        width: 100vw;
        height: makeRem(15);
        top: 0;
        left: 0;
        background : linear-gradient(0deg, rgba($col_black, 0) 0%, rgba($col_black, .02) 29.4%, rgba($col_black, .06) 56.2%, rgba($col_black, .13) 81.91%, rgba($col_black, .2) 100%);
      }
    }
  }
}
