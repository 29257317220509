/* ==========================================================================
   Svg Definition
   ========================================================================== */
.c-svgDef{

  @mixin svgDef{
    &{
      display: none;
    }
  }

  /* 基本レイアウト */
  @include svgDef;

}
