.p-system{
  opacity: 0;

  @include pc{
    @include basicLayout;
    margin-bottom: makeRem(340);
    &__inner{
      position: relative;
    }
    &__title{
      width: makeRem(267);
      margin: 0 auto makeRem(86);
    }
    &__ph{
      &--01{
        width: makePer(315);
        position: absolute;
        top: -(makeRem(100));
        left: makeRem(24);
      }
      &--02{
        width: makePer(287);
        position: absolute;
        top: -(makeRem(60));
        right: makeRem(24);
      }
    }
    &__heading{
      width: makeRem(146);
      margin: 0 auto makeRem(50);
    }
    &__order{
      margin-left: makeRem(74);
      margin-bottom: makeRem(108);
      &Item{
        @include flexLayout(nowrap, flex-start, flex-start);
        margin-bottom: makeRem(16);
      }
      &Number{
        &--01{
          width: makeRem(23);
        }
        &--02{
          width: makeRem(24);
        }
        &--03{
          width: makeRem(24);
        }
        &--04{
          width: makeRem(25);
        }
      }
      &Text{
        margin-left: makeRem(16);
        font-size: makeRem(14);
        span{
          font-weight: bold;
        }
      }
    }
    &__course{
      @include flexLayout(nowrap, space-between, flex-start);
      &Item{
        width: 50%;
      }
      &Heading{
        margin: 0 auto makeRem(15);
        &--01{
          width: makeRem(166);
        }
        &--02{
          width: makeRem(263);
        }
      }
      &Pic{
        margin: 0 auto;
        &--01{
          width: makePer(447,530);
        }
        &--02{
          width: makePer(460,530);
        }
      }
      &Desc{
        float: left;
        margin-top: makeRem(10);
        margin-left: makePer(80,530);
        font-size: makeVw(13,1080);
        &--first{
          margin-left: 0;
        }
        &--last{
          margin-left: makePer(40,530);
        }
        &--last02{
          margin-left: makePer(40,530);
        }
      }
    }
    &__menu{
      position: relative;
      &Bg{
        width: 96.03%;
        margin: makeRem(70) auto 0;
      }
      &Item{
        @include flexLayout(nowrap, flex-start, center);
        &--01{
          position: absolute;
          top: makePer(122,1060);
          left: makePer(108,1060);
        }
        &--02{
          position: absolute;
          top: makePer(122,1060);
          right: makePer(78,1060);
        }
        &--03{
          position: absolute;
          bottom: makePer(122,1060);
          left: makePer(108,1060);
        }
        &--04{
          position: absolute;
          bottom: makePer(202,1060);
          right: makePer(132,1060);
        }
      }
      &Heading{
        margin-right: makeRem(20);
        &--01{
          width: makeVw(90,1060);
        }
        &--02{
          width: makeVw(93,1060);
        }
        &--03{
          width: makeVw(95,1060);
        }
        &--04{
          width: makeVw(69,1060);
          margin-right: makeRem(46);
        }
      }
      &Body{
        @include flexLayout(nowrap, flex-start, flex-start);
        font-size: makeVw(13,1060);
        line-height: makeVw(30,1060);
        &Left{
          margin-right: makeRem(20);
        }
      }
      &Pic{
        width: makePer(62,1060);
        position: absolute;
        bottom: makePer(122,1060);
        right: makePer(2,1060);
      }
      &Ph{
        &--01{
          width: makePer(201,1060);
          position: absolute;
          left: makePer(38,1060);
          bottom: -(makePer(280,1060));
        }
        &--02{
          width: makePer(179,1060);
          position: absolute;
          left: makePer(328,1060);
          bottom: -(makePer(350,1060));
        }
        &--03{
          width: makePer(164,1060);
          position: absolute;
          right: makePer(328,1060);
          bottom: -(makePer(330,1060));
        }
        &--04{
          width: makePer(203,1060);
          position: absolute;
          right: makePer(48,1060);
          bottom: -(makePer(340,1060));
        }
      }
      &Note{
        position: absolute;
        right: 0;
        bottom: -(makeRem(200));
      }
    }

  }

  @include pcL{
    &__course{
      &Desc{
        font-size: makeRem(13);
        &--last{
          margin-left: makePer(80,530);
        }
        &--last02{
          margin-left: makePer(60,530);
        }
      }
    }
    &__menu{
      position: relative;
      &Bg{
        width: 96.03%;
        margin: makeRem(70) auto 0;
      }
      &Item{
        @include flexLayout(nowrap, flex-start, center);
        &--01{
          position: absolute;
          top: makeRem(72);
          left: makeRem(148);
        }
        &--02{
          position: absolute;
          top: makeRem(72);
          right: makeRem(138);
        }
        &--03{
          position: absolute;
          bottom: makeRem(72);
          left: makeRem(148);
        }
        &--04{
          position: absolute;
          bottom: makeRem(102);
          right: makeRem(188);
        }
      }
      &Heading{
        margin-right: makeRem(20);
        &--01{
          width: makeRem(90);
        }
        &--02{
          width: makeRem(93);
        }
        &--03{
          width: makeRem(95);
        }
        &--04{
          width: makeRem(69);
          margin-right: makeRem(46);
        }
      }
      &Body{
        @include flexLayout(nowrap, flex-start, flex-start);
        font-size: makeRem(13);
        line-height: makeRem(30);
        &Left{
          margin-right: makeRem(20);
        }
      }
    }

  }

  @include sp{
    margin-bottom: makeVw(340);
    &__title{
      width: makeVw(201);
      margin: 0 auto makeVw(27);
    }
    &__ph{
      &Box{
        @include flexLayout(nowrap, center, center);
      }
      &--01{
        width: makeVw(173);
      }
      &--02{
        width: makeVw(165);
      }
    }
    &__heading{
      width: makeVw(137);
      margin: makeVw(16) auto makeVw(27);
    }
    &__order{
      padding: 0 makeVw(28);
      margin-bottom: makeVw(45);
      &Item{
        @include flexLayout(nowrap, flex-start, flex-start);
        margin-bottom: makeVw(16);
      }
      &Number{
        &--01{
          width: makeVw(20);
        }
        &--02{
          width: makeVw(22);
        }
        &--03{
          width: makeVw(22);
        }
        &--04{
          width: makeVw(22);
        }
      }
      &Text{
        margin-left: makeVw(10);
        font-size: makeVw(13);
        line-height: makeVw(20);
        span{
          font-weight: bold;
        }
      }
    }
    &__course{
      padding: 0 makeVw(28);
      &Item{
        margin-bottom: makeVw(45);
      }
      &Heading{
        margin: 0 auto makeVw(15);
        &--01{
          width: makeVw(111);
        }
        &--02{
          width: makeVw(177);
        }
      }
      &Pic{
        margin: 0 auto;
        &--01{
          width: makeVw(298);
        }
        &--02{
          width: makeVw(313);
        }
      }
      &Desc{
        text-align: center;
        margin-top: makeVw(10);
        font-size: makeVw(12);
        &Box{
          @include flexLayout(nowrap, space-between, flex-start);
        }
      }
    }
    &__menu{
      position: relative;
      &Item{
        @include flexLayout(nowrap, flex-start, center);
        padding: 0 makeVw(28);
        margin-top: makeVw(16);
        margin-bottom: makeVw(16);
        &--04{
          margin-bottom: makeVw(75);
        }
      }
      &Heading{
        margin-right: makeVw(20);
        &--01{
          width: makeVw(74);
        }
        &--02{
          width: makeVw(74);
        }
        &--03{
          width: makeVw(75);
        }
        &--04{
          width: makeVw(57);
          margin-right: makeVw(38);
        }
      }
      &Body{
        @include flexLayout(nowrap, flex-start, flex-start);
        font-size: makeVw(11);
        line-height: makeVw(20);
        &Left{
          margin-right: makeVw(10);
        }
      }
      &Line{
        &Inner{
          padding: 0 makeVw(28);
        }
      }
      &Pic{
        width: makeVw(46);
        position: absolute;
        right: makeVw(16);
        bottom: makeVw(50);
      }
      &Ph{
        &--01{
          width: makeVw(116);
          position: absolute;
          left: makeVw(58);
          bottom: -(makeVw(176));
        }
        &--02{
          width: makeVw(103);
          position: absolute;
          right: makeVw(50);
          bottom: -(makeVw(176));
        }
        &--03{
          width: makeVw(94);
          position: absolute;
          left: makeVw(58);
          bottom: -(makeVw(46));
        }
        &--04{
          width: makeVw(116);
          position: absolute;
          right: makeVw(50);
          bottom: -(makeVw(46));
        }
      }
      &Note{
        padding: 0 makeVw(28);
        font-size: makeVw(12);
        position: absolute;
        bottom: -(makeVw(246));
      }
    }

  }
}