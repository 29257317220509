.p-information{
  @include basicLayout;
  opacity: 0;
  &__map{
    position: relative;
    height: 0;
    overflow: hidden;
    iframe{
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }
  @include pc{
    &__inner{
      position: relative;
    }
    &__title{
      margin-left: -(makeRem(32));
      img{
        width: makeRem(293);
        margin: 0 auto makeRem(60);
      }
    }
    &__desc{
      text-align: center;
      margin-bottom: makeRem(60);
      font-size: makeRem(16);
      line-height: makeRem(37);
    }
    &__box{
      @include flexLayout(nowrap, center, center);
      flex-direction: column;
    }
    &__map{
      width: makeRem(400);
      height: makeRem(400);
      border-radius: 10px;
      padding-right: makeRem(18);
    }
    &__hours{
      p{
        text-align: center;
        margin-bottom: makeRem(60);
        font-size: makeRem(14);
        line-height: makeRem(24);
        font-weight: bold;
      }
    }
    &__text{
      width: makeRem(208);
    }

  }

  @include sp{
    &__inner{
      position: relative;
    }
    &__title{
      margin-left: -(makeVw(20));
      img{
        width: makeVw(223);
        margin: 0 auto makeVw(40);
      }
    }
    &__desc{
      text-align: center;
      margin-bottom: makeVw(40);
      font-size: makeVw(13);
      line-height: makeVw(25);
    }
    &__map{
      padding-bottom: 53.33%; /*750:450*/
      margin-left: -(makeRem(10));
      margin-right: -(makeRem(10));
    }
    &__hours{
      p{
        margin-bottom: makeVw(40);
        text-align: center;
        font-size: makeVw(11);
        line-height: makeVw(24);
        font-weight: bold;
      }
    }
    &__text{
      width: makeVw(157);
      margin: 0 auto;
    }
  }
  
}