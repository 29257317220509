@-ms-viewport{
  width: auto;
  initial-scale: 1;
}
@viewport{
  width: device-width;
  initial-scale: 1;
}
html{
  width: 100%;
  height: 100%;
  @include pc {
    font-size: #{(($contents_fs * 100) / 16) + '%'};
  }
  @include sp {
    font-size: makeVw($contents_fs);
  }
}
body{
  width: inherit;
  height: inherit;
  color: $col_key;
  font-family: $font_main;
  font-weight: 400;
  @include lineHeight;
  letter-spacing: $lts50;
  background: $col_bg;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: 'palt';
  font-feature-settings: 'palt';
  @include pc {
    font-size: 1.3rem;
  }
  @include sp {
    font-size: 1.4rem;
  }
}

/* ==========================================================================
   Common Parts
   ========================================================================== */
