/* ==========================================================================
   Blink
   ========================================================================== */
@keyframes blink {
  75%{
    opacity: 0;
  }
}

/* ==========================================================================
   Scroller
   ========================================================================== */
@keyframes scrollLeft {
  0%{
    transform: translate3d(-100%,0,0);
    opacity: 0;
  }

  15%{
    transform: translate3d(-98%,0,0);
  }

  85%{
    transform: translate3d(-15%,0,0);
    opacity: 0.5;
  }

  100%{
    transform: translate3d(0%,0,0);
    opacity: 1;
  }
}

@keyframes scrollRight {
  0%{
    transform: translate3d(100%,0,0);
    opacity: 0;
  }

  15%{
    transform: translate3d(98%,0,0);
  }

  85%{
    transform: translate3d(15%,0,0);
    opacity: 0.5;
  }

  100%{
    transform: translate3d(0%,0,0);
    opacity: 1;
  }
}

@keyframes scrollBottom {
  0%{
    transform: translate3d(0,20%,0);
    opacity: 0;
  }

  15%{
    transform: translate3d(0,18%,0);
  }

  85%{
    transform: translate3d(0,5%,0);
    opacity: 0.5;
  }

  100%{
    transform: translate3d(0,0%,0);
    opacity: 1;
  }
}

/* ==========================================================================
   Scroll In
   ========================================================================== */
@keyframes scrollIn {}


.c-animatedLeft {
  -webkit-animation-name: scrollLeft;
  animation-name: scrollLeft;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.c-animatedRight {
  -webkit-animation-name: scrollRight;
  animation-name: scrollRight;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.c-animatedBottom {
  -webkit-animation-name: scrollBottom;
  animation-name: scrollBottom;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.c-animatedFadeIn {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
 
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
 
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
 
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
