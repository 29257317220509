/* ==========================================================================
   Grid
   ========================================================================== */
.c-grid{

  $count: 12;

  @mixin grid(){
    @include flexLayout;
    @include pc {}
    @include sp {}
  }

  /* 親の設定 */
  @include grid;

  /* 子要素の設定 */
  @for $i from 1 through $count {
    &__cell#{$i}{
      @include pc {
        width: (100% * ($i / $count));
        @for $j from 1 through $count {
          &--sp#{$j}{
            width: (100% * ($i / $count));
          }
        }
      }
      @include sp {
        @for $i from 1 through $count {
          &--sp#{$i}{
            width: (100% * ($i / $count));
          }
        }
      }
    }
  }

}
